<template>
  <div class="" v-if='!formLoading'>
    <BaseForm 
      v-if='formObj != null && !formLoading' 
      :editObj='formObj' 
      :getBaseData='getBaseData' 
      ref='baseForm'
    />
    <hr/>
    <ResponsiveFieldsForm 
      v-if='formObj != null && !formLoading' 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
    />
    <!-- <hr/> -->
    <!-- <ImgForm 
      :editObj='formObj' 
      :getMediaData='getMediaData'
      :getImgData='getImgData' 
      ref='imgForm'
      :multiple="true"
    /> -->
    <ImgFormMulti 
      :editObj='formObj' 
      :getMediaData='getMediaData'
      :getImgData='getImgData' 
      ref='imgForm'
      :multiple="true"
    />
    
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';
import ImgFormMulti from './ImgFormMulti.vue';

import {
  BRow, BCol
} from 'bootstrap-vue';

import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    ResponsiveFieldsForm,
    BaseForm,
    ProductRestForm,
    ImgForm,
    ImgFormMulti,
    BRow, BCol
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        // color: '#FFFFFF',
        status: true,
      }
    }
  },

  props: ['editObj', 'closeModal',],
  mounted() {
    console.log(this.editObj)
    this.getLabels({});
    if(this.editObj != null) {
      this.getProject(this.editObj.id)
        .then(res => {
          this.formObj = res.data.results;
          this.formObj.descriptions = {
            ar: this.formObj.description.ar,
            en: this.formObj.description.ar,
          }

          if(this.editObj.labels && this.editObj.labels.length > 0) {
            if(this.editObj.labels[0]) {
              this.formObj.label1 = {
                id: this.editObj.labels[0].id,  
                title: this.editObj.labels[0].title,  
              }
            }
            if(this.editObj.labels[1]) {
              this.formObj.label2 = {
                id: this.editObj.labels[1].id,  
                title: this.editObj.labels[1].title,  
              }
            }
          }
          console.log(this.formObj)
          this.formData.id = this.editObj.id;
          setTimeout(() => {
            this.formLoading = false;
          }, 100)
        })
      // this.getLabel(this.editObj.id)
      //   .then(res => {
      //     this.formData.id = this.editObj.id;
      //     this.formLoading = false;
      //   })
    }else {
      this.formObj = {
        status: true
      }
    }
  },
  methods: {
    ...mapActions(['getPages', 'getProjects', 'createProject', 'getProject', 'getLabels']),
    formSubmit()  {
      this.$refs.restForm.submit()
        .then(res => {
          if(res == true) {
            if(res == true) {
              this.$store.commit('setModalLoading', true);
              this.$refs.restForm.submit();
              this.$refs.imgForm.submit();
              this.$refs.baseForm.submit();
              console.log(this.formData)
              setTimeout(() => {
                this.createProject(this.formData)
                  .then(res => {
                    this.closeModal();
                    this.getProjects({});
                    this.$store.commit('setModalLoading', false);
                  })
              }, 100)
            }
          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    // getImgData(data) {
    //   console.log(data)
    //   this.formData.base64 = [];
    //   if(this.editObj && this.editObj.image) {
    //     this.formData.base64 = [
    //       {
    //         ...this.editObj.image,
    //         delete: true
    //       }
    //     ]
    //   }
        
    //   this.formData.base64 = [
    //     ...this.formData.base64,
    //     data,
    //   ];
    // },
    getImgData(data) {
      this.formData.base64 = [];
      this.formData.base64 = [
        ...this.formData.base64,
        ...data,
      ]
    },
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getMediaData(data) {
      if(this.isFileImage(data)) {
        this.formData.media_type = 0;
      }else {
        this.formData.media_type = 1;
      }
      this.formData.base64 = data;
    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if(this.formData.descriptions.ar == null || this.formData.descriptions.en == null) {
        if(this.formData.descriptions.ar == null) {
          this.formData.descriptions.ar = ''
        }
        if(this.formData.descriptions.en == null) {
          this.formData.descriptions.en = ''
        }
      }
    },
    searchCategories(searchText) {
      this.getCategories({
        path: searchText,
        status: 1,
      })
    },
    searchLabels(searchText) {
      this.getLabels({
        path: searchText,
        status: 1,
      })
    },
  },

  computed: {
    ...mapGetters(['product', 'modalLoading', 'categories', 'labels']),
    filteredCategories() {
      return this.categories.filter(el => el.parent_id && el.parent_id != null)
    },
    restFields() {
      return [
        {name: 'Client Name', type: 'text', rules: 'required', model: 'client_name', },
        {name: 'cost', type: 'number', rules: 'required', model: 'cost', },
        {name: 'duration', type: 'text', rules: 'required', model: 'duration', },
        // {name: 'path', type: 'text', rules: 'required', model: 'path' },
        {name: 'ord', type: 'number', rules: 'required', model: 'ord' },
        {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
        {
          name: 'category', type: 'searchSelect', rules: 'required', 
          model: 'category_id', options: this.filteredCategories, label: 'title', 
          searchItems: this.searchCategories,
          cols: 12,
        },
        {
          name: 'Label', type: 'searchSelect', rules: '', 
          model: 'label1', options: this.labels, label: 'title', 
          searchItems: this.searchLabels,
          cols: 6,
        },
        {
          name: 'Label', type: 'searchSelect', rules: '', 
          model: 'label2', options: this.labels, label: 'title', 
          searchItems: this.searchLabels,
          cols: 6,
        },
      ]
    }
  }

}
</script>