import Axios from '../../libs/nabdIns';

export default {
    state: {
        newsList: [],
        News: {},
        showNews: null
    },

    getters: {
        newsList: (state) => state.newsList,
        news: (state) => state.news,
        showNews: (state) => state.showNews
    },

    mutations: {
        setNewsList(state, payload) {
            state.newsList = payload;
        },
        setNews(state, payload) {
            state.news = { ...payload };
        },
        addNews(state, payload) {
            state.newsList.push(payload);
        },
        updateNews(state, payload) {
            let index = state.newsList.findIndex(el => el.id == payload.id);
            state.newsList.splice(index, 1, payload);
        },
        removeNews(state, payload) {
            let index = state.newsList.findIndex(el => el.id == payload);
            state.newsList.splice(index, 1);
        },
        showNews(state, payload) {
            state.showNews = payload;
        }

    },
    actions: {
        loadNewsList({ commit }, payload) {
            return new Promise((resolve, reject) => {
                console.log(payload);
                Axios.post("/admin/news", { ...payload }).then(res => {
                    if (res.status == 200) {
                        commit("setNewsList", res.data.results.data);
                        resolve(res);
                    }
                }).catch(rej => {
                    reject(rej);
                });
            });
        },
        loadShowedNews({ commit }, payload) {
            return new Promise((resolve, reject) => {
                Axios.post(`/admin/news/show/${payload}`).then(res => {
                    if (res.status == 200) {
                        commit("showNews", res.data.results);
                        resolve(res);
                    }
                }).catch(rej => {
                    reject(rej);
                });
            });
        },
        deleteNews({ commit }, payload) {
            console.log(payload);
            return new Promise((resolve, reject) => {
                Axios.post(`/admin/news/delete/${payload}`).then(res => {
                    if (res.status == 200) {
                        console.log(res);
                        commit("removeNews", payload);
                        resolve(res);
                    }
                }).catch(err => {
                    reject(err);
                });
            });
        },
        updateNews({ commit }, payload) {
            console.log(payload);
            return new Promise((resolve, reject) => {
                Axios.post(`/admin/news/save`, {
                    id: payload.id,
                    titles: payload.titles,
                    descriptions: payload.descriptions,
                    tags: payload.tags ? [{...payload.tags}] : undefined,
                    ord: payload.ord,
                    category_id: payload.category_id,
                    status: payload.status,
                    image_base64: payload.base64 ?  {...payload.base64[0]} : undefined,
                }).then(res => {
                    if (res.status == 200) {
                        console.log(res);
                        commit("updateNews", payload);
                        resolve(res);
                    }
                }).catch(err => {
                    console.log(err);
                    reject(err);
                });
            });
        }
    }
};