import Axios from '../../libs/strataIns';

export default {
  state: {
    orders: [],
    order: {},
    ordersMeta: {},
    
    ordersStatus: [],
    orderStatus: {},
  },

  getters: {
    orders: (state) => state.orders,
    order: (state) => state.order,
    ordersStatus: (state) => state.ordersStatus,
    orderStatus: (state) => state.orderStatus,
    ordersMeta: (state) => state.ordersMeta,
  },

  mutations: {
    setOrders(state, payload) {
      state.orders = [...payload]
    },
    setOrder(state, payload) {
      state.order = {...payload}
    },    
    setOrdersMeta(state, payload) {
      state.ordersMeta = {...payload}
    },    
    setOrderStatus(state, payload) {
      state.orderStatus = {...payload};
    },
    setOrdersStatus(state, payload) {
      state.ordersStatus = [...payload];
    },
    removeStatus(state, payload) {
      let index = state.ordersStatus.findIndex(el => el.id == payload);
      state.ordersStatus.splice(index, 1);
    }

  },

  actions: {
    getOrders({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/products/orders`, {
          id: payload.id,
          keywords: payload.keywords,
          driver_id: payload.driver_id,
          seller_id: payload.seller_id,
          page: payload.page,
          status_id: payload.status_id,
          from_date: payload.from_date,
          to_date: payload.to_date,
          limit: payload.limit,
          sort_by: payload.sort_by,
          sort_as: payload.sort_as,
        })
        .then(res => {
          if(res.data.results != null) {
            if(payload.id && payload.id != undefined) {
              commit('setOrder', res.data.results);
            }else {
              commit('setOrders', res.data.results);
              commit('setOrdersMeta', res.data.meta);
            }
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    updateOrder({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/products/orders/save`, {
          id: payload.id,
          status_id: payload.status_id,
          paid: payload.paid,
          payment_method: payload.paid == true ? "cod": null,
          discount: payload.discount,
          driver_id: payload.driver_id,
          seller_id: payload.seller_id,
          cart: payload.cart,
        })
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getOrdersStatus({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/products/orders/status`, {
          id: payload.id,
          page: payload.page
        })
        .then(res => {
          if(res.data.results != null) {
            if(payload.id && payload.id != undefined) {
              commit('setOrderStatus', res.data.results)
            }else {
              commit('setOrdersStatus', res.data.results)
            }
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getOrderStatus({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/products/orders/status/show/${payload.id}`, {
          id: payload.id,
          page: payload.page
        })
        .then(res => {
          if(res.data.results != null) {
            if(payload.id && payload.id != undefined) {
              commit('setOrderStatus', res.data.results)
            }else {
              commit('setOrdersStatus', res.data.results)
            }
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    createOrderStatus({commit}, payload) {
      console.log(payload.titles)
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/products/orders/status/save`, {
          id: payload.id,
          titles: {...payload.titles},
          default_start: payload.default_start,
          ord: payload.ord,
          status: payload.status,
          status_value: payload.status_value,
        })
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    } ,
    deleteOrderStatus({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/products/orders/status/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeStatus', payload)
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    makeOrder({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/products/orders/make`, {
          client_id: payload.client.id,
          client: payload.client,
          notes: payload.client.notes,
          recurring: payload.recurring,
          cart: payload.cart,
        })
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }
  }
}