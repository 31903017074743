import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index'

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
      redirect: {
        path: '/shows'
      }
    },
    // {
    //   path: '/contact-us',
    //   name: 'ContactUs',
    //   component: () => import('@/views/ContactUs.vue'),
    //   meta: {
    //     pageTitle: 'Contact Us',
    //     breadcrumb: [
    //       {
    //         text: 'Contact Us',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/news',
    //   name: 'News',
    //   component: () => import('@/views/News.vue'),
    //   meta: {
    //     pageTitle: 'News',
    //     breadcrumb: [
    //       {
    //         text: 'News',
    //         active: true,
    //       },
    //     ],
    //     perms: ['news']
    //   },
    // },
    {
      path: '/presenters',
      name: 'Presenters',
      component: () => import('@/views/Presenters.vue'),
      meta: {
        pageTitle: 'Presenters',
        breadcrumb: [
          {
            text: 'Presenters',
            active: true,
          },
        ],
        perms: ['announcer']
      },
    },
    {
      path: '/shows',
      name: 'Shows',
      component: () => import('@/views/Shows.vue'),
      meta: {
        pageTitle: 'Shows',
        breadcrumb: [
          {
            text: 'Shows',
            active: true,
          },
        ],
        perms: ['show']
      },
    },
    {
      path: '/audio-stream',
      name: 'AudioStream',
      component: () => import('@/views/StreamsLayout.vue'),
      meta: {
        pageTitle: 'AudioStream',
        breadcrumb: [
          {
            text: 'AudioStream',
            active: true,
          },
        ],
        perms: ['audioStream']
      },
    },
    {
      path: '/video-stream',
      name: 'VideoStream',
      component: () => import('@/views/StreamsLayout.vue'),
      meta: {
        pageTitle: 'VideoStream',
        breadcrumb: [
          {
            text: 'VideoStream',
            active: true,
          },
        ],
        perms: ['videoStram']
      },
    },
    {
      path: '/news',
      name: 'News',
      component: () => import('@/views/News.vue'),
      meta: {
        pageTitle: 'News',
        breadcrumb: [
          {
            text: 'News',
            active: true,
          },
        ],
        perms: ['news']
      },
    },
    {
      path: '/categories',
      name: 'Categories',
      component: () => import('@/views/Categories.vue'),
      meta: {
        pageTitle: 'Categories',
        breadcrumb: [
          {
            text: 'Categories',
            active: true,
          },
        ],
        perms: ['category']
      },
    },
    {
      path: '/gallery',
      name: 'Media',
      component: () => import('@/views/Media.vue'),
      meta: {
        pageTitle: 'Gallery',
        breadcrumb: [
          {
            text: 'Gallery',
            active: true,
          },
        ],
        perms: ['media']
      },
    },
    {
      path: '/users',
      name: 'Accounts',
      component: () => import('@/views/Accounts.vue'),
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
        perms: ['users', 'accounts'],
      },
    },
    {
      path: '/roles',
      name: 'Roles',
      component: () => import('@/views/Roles.vue'),
      meta: {
        pageTitle: 'Roles',
        breadcrumb: [
          {
            text: 'Roles',
            active: true,
          },
        ],
        perms: ['roles']
      },
    },
    {
      path: '/settings',
      name: 'Settings',
      component: () => import('@/views/Settings.vue'),
      meta: {
        pageTitle: 'Settings',
        breadcrumb: [
          {
            text: 'Settings',
            active: true,
          },
        ],
        perms: ['settings']
      },
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: '404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },

  ],
});


router.beforeEach((to, from, next) => {
  let afterDot = [
    'settings',
    'products'
  ]
  if(to.meta.perms) {
    let userInterval = setInterval(() => {
      if(store.getters.user) {
        // console.log(store.getters.user)
        window.clearInterval(userInterval)
        let userPerms = store.getters.user.user.permissions;
        if(userPerms.includes('admin')) {
          next()
        }else {
          const intersection = userPerms.filter(element => {
            if(element.includes('products') || element.includes('settings')) {
              element = element.split('.')[1]
            }
            return to.meta.perms.find(key => element.toLowerCase().includes(key))
          });
          if(intersection.length > 0) {
            next()
          }else {
            next({
              name: '404'
            })
          }
        }
      }
    }, 100)
  }else {
    next()
  }
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
