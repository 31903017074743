export default [
  {
    title: 'Programs',
    route: 'Shows',
    icon: "ListIcon",
    permKeys: ['show']
  },
  {
    title: 'Presenters',
    route: 'Presenters',
    icon: "MicIcon",
    permKeys: ['announcer']
  },
  {
    title: 'News',
    route: 'News',
    icon: 'GridIcon',
    permKeys: ['category']
  },
  {
    title: 'News Category',
    route: 'Categories',
    permKeys: ['category']
  },
  {
    title: 'Gallery',
    route: 'Media',
    icon: 'ImageIcon',
    permKeys: ['media']
  },
  {
    title: 'Audio Broadcast',
    route: 'AudioStream',
    icon: 'MusicIcon',
    permKeys: ['audioStream']
  },
  {
    title: 'Video Stream',
    route: 'VideoStream',
    icon: 'FilmIcon',
    permKeys: ['videoStram']
    
  },
  {
    title: 'Users',
    route: 'Accounts',
    icon: '',
    permKeys: ['accounts', 'settings']
  },
  {
    title: 'Roles',
    route: 'Roles',
    icon: 'LockIcon',
    permKeys: ['roles']
  },
  {
    title: 'Settings',
    route: 'Settings',
    icon: 'SettingsIcon',
    permKeys: ['settings']
  },
  // {
  //   title: 'News',
  //   // route: 'News',
  //   icon: 'GridIcon',
  //   children: [
      

  //   ]
  // },
  
  // {
  //   title: 'Services',
  //   route: 'Services',
  //   icon: 'ServerIcon',
  // },

  // {
  //   title: 'Contacts',
  //   route: 'Contacts',
  //   icon: 'MailIcon',
  //   children: [
  //     {
  //       title: 'Contact Us',
  //       route: 'ContactUs',
  //     },
  //     {
  //       title: 'Subscribtions',
  //       route: 'Subscribtions',
  //     },
  //   ]
  // },
];
