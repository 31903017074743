import Axios from '../../libs/strataIns';
import auth from '../auth/auth';
import i18n from '../../libs/i18n';
import store from '../auth/auth';

export default {
  state: {
    products: [],
    product: {},
    
    productsMeta: {},
    // orderStatus: {},
  },

  getters: {
    products: (state) => state.products,
    product: (state) => state.product,
    productsMeta: (state) => state.productsMeta,
    // orderStatus: (state) => state.orderStatus,
  },

  mutations: {
    setProducts(state, payload) {
      state.products = [...payload]
    },
    setProductsMeta(state, payload) {
      state.productsMeta = {...payload}
    },
    setProduct(state, payload) {
      state.product = {...payload}
    },
    removeProduct(state, payload) {
      let index = state.products.findIndex(el => el.id == payload);
      state.products.splice(index, 1);
    }

  },

  actions: {
    getProducts({commit}, payload) {
      let user = store.state.user;
      let token = user ? user.token : null;
      let exporting = payload.export == 1;
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/products`, {
          id: payload.id,
          keywords: payload.keywords,
          category_id: payload.category_id,
          status: payload.status,
          page: payload.page,
          load_categories: payload.load_categories,
          export: payload.export,
          sort_by: payload.sort_by,
          sort_as: payload.sort_as,
          limit: payload.limit,
        },)
        .then(res => {
          if(exporting) {
            resolve(res)
          }
          if(res.data.results != null) {
            commit('setProducts', res.data.results);
            commit('setProductsMeta', res.data.meta);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getProduct({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/products/show/${payload}`, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setProduct', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    },
    createProduct({commit, state}, payload) {
      console.log(state)
      let fd = new FormData();

      fd.append('id', payload.id);
      fd.append('titles', payload.titles);
      fd.append('descriptions', payload.descriptions);
      fd.append('status', payload.status);
      fd.append('featured', payload.featured);
      fd.append('categorys_id', payload.categorys_id);
      fd.append('items', payload.items);
      fd.append('stepper', payload.stepper);
      fd.append('images', payload.base64);

      let data = {
        id: payload.id,
        titles: payload.titles,
        descriptions: payload.descriptions,
        status: payload.status,
        featured: payload.featured,
        categorys_id: payload.categorys_id,
        items: payload.items,
        // "items": [
        //     {
        //         "id": "xa123",
        //         "price": 12.5,
        //         "quantity": 42,
        //         "titles": {
        //             "en": "item 1",
        //             "ar": "صنف 1"
        //         }
        //     }
        // ],
        stepper: payload.stepper,
        images: payload.base64 
      }
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/products/save`,  data ,{
          // headers: {
          //   'content-type': 'multipart/form-data',
          // }
        })
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteProduct({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`admin/products/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeProduct', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    importProducts({commit}, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`admin/products/import`, {
        //   "options": {
        //     "barcode": "A",
        //     "category": "B",
        //     "name": {
        //         "ar": "C",
        //         "en": "D"
        //     },
        //     "image_path": "E",
        //     "price": "F",
        //     "quantity": "",
        //     "start": 2
        // },
        // "file": {
        //     "name": "منتجات الموقع.xls",
        //     "file": "data:application/vnd.ms-excel;base64,...etc to entire file",
        //     "ext": "xls"
        // },
          options: payload.options,
          file: payload.file,
          locale: i18n.locale
        }).then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }
  },
  modules: {
    // auth     
  }
}