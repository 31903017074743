<template>
  <!-- <b-card> -->
  <!-- </b-card> -->
  <div >
    <UploadImages @changed="handleImages"/>  
    <b-button
      v-if='files.length > 0 && resolvePermission("media")'
      @click='submit'
      variant="primary"
      class="my-1"
    >
      Submit
    </b-button>
    <hr />
    <b-card v-if='allMedia.data && allMedia.data.length > 0'>
      <span v-for='file in allMedia.data' :key='file.id' class="">
        <b-img 
          class="m-1 image-border"
          rounded
          height="80" 
          width="80" 
          :src="file.url" />
      </span>
    </b-card>
  </div>
</template>

<script>
import UploadImages from "vue-upload-drop-images"
import {
  BCard, BImg, BButton
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: 
  {
    UploadImages,
    BCard, BImg, BButton
  },
  mounted() {
    this.getMedia({
      limit: 100,
    })
      .then(res=> {
        console.log(res.data.results)
      })
  },
  data() {
    return {
      files: []
    }
  },

  methods: {
    ...mapActions(['getMedia', 'createMedia']),
    handleImages(files) {
      this.files = files;
      this.$forceUpdate();
    },
    submit() {
      this.createMedia(this.files)
        .then(res => {
          // this.$router.go()
          console.log(res);
        })
    }
  },

  computed: {
    ...mapGetters(['allMedia'])
  }
}

</script>