<template>
  <div class="" v-if='!formLoading && formObj != null'>
    <BaseForm 
      :editObj='formObj' 
      :getBaseData='getBaseData' 
      ref='baseForm'
    />
    <hr/>
    <ResponsiveFieldsForm 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
    />
    <hr/>
    <!-- <DaysRepeater :editObj='formObj' :getRepeaterData='getRepeaterData' ref='daysRepeaterForm'/> -->
    <b-row class="my-1 px-2" v-if='editObj && editObj.programs'>
      <h5>Programs</h5>
      <b-table striped :fields="programsFields" :items="editObj.programs"></b-table>
    </b-row>
    <!-- <hr/> -->
    <ImgForm 
      :editObj='formObj' 
      :getImgData='getImgData' 
      ref='coverimgForm'
      title='Cover'
    />
    <ImgForm 
      :editObj='formObj' 
      :getImgData='getImgData' 
      ref='playerimgForm'
      title='Player'
    />
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'

import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';
import DaysRepeater from './DaysRepeater.vue';

import {
  BRow, BCol, BCard, BCardHeader, BTable
} from 'bootstrap-vue';

import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    ResponsiveFieldsForm,
    DaysRepeater,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow, BCol, BCard,
    BCardHeader, BTable
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        color: '#FFFFFF',
        status: true,
      },
      programsFields: [
        {
          key: 'id',
          label: this.$t('#')
        },
        {
          key: 'title',
          label: this.$t('title')
        },
        {
          key: 'description',
          label: this.$t('description')
        },
      ]
    }
  },

  props: ['editObj', 'closeModal',],
  mounted() {
    console.log(this.editObj)
    if(this.editObj != null) {
      this.formLoading = true
      this.getPresenter(this.editObj.id).then(res => {
        this.formObj = res.data.results
        if(this.editObj.contact) {
          this.formObj.facebook = this.editObj.contact.facebook;
          this.formObj.twitter = this.editObj.contact.twitter;
          this.formObj.instagram = this.editObj.contact.instagram;
          this.formObj.youtube = this.editObj.contact.youtube;
          this.formObj.whatsapp = this.editObj.contact.whatsapp;
        }
        setTimeout(() => {
          this.formLoading = false;
        }, 100)
        this.$forceUpdate();
      })
      // this.getLabel(this.editObj.id)
      //   .then(res => {
      //     this.formData.id = this.editObj.id;
      //     this.formLoading = false;
      //   })
    }else {
      this.formLoading = false;
      this.formObj = {}
    }
  },
  methods: {
    ...mapActions(['getShows', 'getPresenters', 'getShow', 'createShow', 'createPresenter', 'getPresenter']),
    formSubmit()  {
      this.$refs.baseForm.submit()
        .then(res => {
          if(res == true) {
            
            this.$refs.restForm.submit()
            .then(res => {
              if(res == true) {
                this.$store.commit('setModalLoading', true);
                this.$refs.restForm.submit();
                this.$refs.baseForm.submit();
                // this.$refs.daysRepeaterForm.submit();
                this.$refs.coverimgForm.submit();
                this.$refs.playerimgForm.submit();
                console.log(this.formData)
                this.checkNulldesc();
                setTimeout(() => {
                  this.createPresenter(this.formData)
                    .then(res => {
                      this.closeModal();
                      this.getPresenters({});
                      this.$store.commit('setModalLoading', false);
                    })
                }, 100)
              }
            })

          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data, key) {
      console.log(data)
      this.formData.base64 = [];
      if(this.editObj && this.editObj.image) {
        this.formData.base64 = [
          {
            ...this.editObj.image,
            delete: true
          }
        ]
      }
      if(key == 'Cover') {
        this.formData.cover_image_base64 = data;
      }else if(key == 'Player'){
        this.formData.image_base64 = data;
      }else {
        this.formData.base64 = [
          ...this.formData.base64,
          data,
        ];
      }
      // console.log(this.formData.base64)
    },
      
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getRepeaterData(data) {
      this.formData.times = data;
    },
    checkNulldesc() {
      if(this.formData.descriptions.ar =='' || this.formData.descriptions.ar == null) {
        this.formData.descriptions.ar = this.formData.descriptions.en
      }
      if(this.formData.descriptions.en == null || this.formData.descriptions.en == '') {
        this.formData.descriptions.en = this.formData.descriptions.ar
      }
    },
    searchPresenters(searchText) {
      this.getPresenters({
        keywords: searchText
      })
    }
  },

  computed: {
    ...mapGetters(['presenters', 'modalLoading']),
    restFields() {
      return [
        {name: 'Facebook', type: 'text', rules: '', model: 'facebook', cols: 4},
        {name: 'Twitter', type: 'text', rules: '', model: 'twitter', cols: 4},
        {name: 'Instagram', type: 'text', rules: '', model: 'instagram', cols: 4},
        {name: 'Whatsapp', type: 'text', rules: '', model: 'whatsapp', },
        {name: 'Youtube', type: 'text', rules: '', model: 'youtube', },
        // {type: 'divider'},
        // {
        //   name: 'Announcers', type: 'searchSelect', rules: 'required', model: 'announcers_id', 
        //   multi: true , options: this.presenters, label: 'title', searchItems: this.searchPresenters, cols:12
        // },
        // {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
      ]
    }
  }

}
</script>