<template>
  <validation-observer ref="simpleRules" v-if='!modalLoading && !formLoading'>
     <div>
      <b-form
        ref="form"
        
        class="repeater-form"
        @submit.prevent="submit"
        v-if='items.length > 0'
      >
        <!-- Row Loop -->
        <h4>
          {{$t('Item')}}
        </h4>
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Item Name -->
          <!-- <b-col cols="3">
            <label for='id'>
              {{$t('code')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='id'
            >
              <b-form-input
                id="id"
                placeholder="ID"
                v-model='item.id'
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col> -->

          <!-- {{item.titles}} -->
          <!-- <b-col cols="3">
            <label for='title'>
              {{$t('title')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='title'
            >
              <b-input-group >
                <b-form-input
                  id="title"
                  :placeholder="$t(item.nameLang)"
                  v-model='item[item.nameLang]'
                  :state="errors.length > 0 ? false:null"
                  @change='checkEmptyLocale(item)'
                />
                <b-input-group-append>
                  <b-button variant="primary" @click='changeNameLang(item)'>
                    {{item.nameLang}}
                  </b-button>
                </b-input-group-append>
               </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col> -->

          <!-- Cost -->
          <b-col>
            <label for='price'>
              {{$t('price')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='price'
            >
              <b-form-input
                id="id"
                placeholder="Price"
                v-model='item.price'
                :state="errors.length > 0 ? false:null"
                type='number'
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- Quantity -->
          <!-- <b-col cols="2">
            <label for='Quantity'>
              {{$t('Quantity')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='Quantity'
            >              
              <b-input-group >
                <b-form-input
                  id="id"
                  placeholder="Quantity"
                  v-model='item.quantity'
                  :state="errors.length > 0 ? false:null"
                  type='number'
                  :disabled='item.quantity == -1'
                />
                <b-input-group-append>
                  <b-button variant="primary" class="btn-icon" @click='changeQuantityType(item)'>
                     <feather-icon
                        icon="MoreHorizontalIcon"
                        class="mr-25"
                      />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col> -->

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <!-- <span>Delete</span> -->
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <!-- <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
      class="btn-icon my-1"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button> -->
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
    BFormCheckbox, BFormTextarea, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations';
import { mapActions, mapGetters} from 'vuex';


export default {
  components: {
    BForm,
    BRow,
    BCol, 
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox, BFormTextarea, BInputGroup, BInputGroupAppend,
    ValidationProvider, ValidationObserver
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
      ],
      defaultItem: {
        titles: {
          ar: '',
          en: '',
        },
        ar: '',
        en: '',
        price: null,
        quantity: 0,
        id: null,
        nameLang: 'ar'
      },
      nextTodoId: 1,
      nameLang: 'ar',
      formLoading: false,
      tempitems: [],
      required,
    }
  },
  props: ['getRepeaterData', 'editObj'],
  watch: {
    items: function() {
      if (this.items.length == 0) {
        this.repeateAgain();
      }
    }
  },  
  mounted() {
    this.formLoading = true;
    if(this.editObj != null) {
      this.tempitems = [...this.editObj.items];
      this.tempitems.forEach(el => {
        let item = {
          nameLang: 'ar',
          ar: el.titles.ar,
          en: el.titles.en,
        };
        el = {
          ...item,
          ...el
        };
        this.items.push(el);
      })
      this.formLoading = false;

    }else {
      this.formLoading = false;
      this.repeateAgain()
    }
    // this.formLoading = false;
  },
  methods: {
    repeateAgain() {
      let newId = this.createItemId(); 
      this.items.push({
        ...this.defaultItem,
        id: newId,
        ar: newId,
        en: newId,
      })
    },
    submit() {
      return this.$refs.simpleRules.validate()
        .then(res => {
          this.items.forEach(el => {
            this.checkEmptyLocale(el)
          })
          this.valid = res;
          if (res == true) {
            this.getRepeaterData(this.items)
            return true;
          }else {
            return false;
          }
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    changeNameLang(item) {
      if(item.nameLang == 'ar') {
        item.nameLang = 'en'
      }else {
        item.nameLang = 'ar'
      }
    },
    checkEmptyLocale(item) {
      // console.log(item)
      if(!item.ar || !item.en) {
        if(!item.ar) {
          item.ar = item.en;  
        }else {
          item.en = item.ar;
        }
      }
      if(!item.ar && !item.en) {
        item.ar = item.en = item.id;
      }
      console.log(item)
      item.titles.ar = item.ar;
      item.titles.en = item.en;
    },
    changeQuantityType(item) {
      console.log(item)
      if(item.quantity == -1) {
        item.quantity = 1;
      }else {
        item.quantity = -1;
      }
    }
  },

  computed: {
    ...mapGetters(['modalLoading'])
  }
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>