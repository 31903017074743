import Axios from '../../libs/strataIns';

export default {
  state: {
    jobCategories: [],
    jobCategory: {},
    
    jobs: [],
    job: {},
  },

  getters: {
    jobCategories: (state) => state.jobCategories,
    jobCategory: (state) => state.jobCategory,
    jobs: (state) => state.jobs,
    job: (state) => state.job,
  },

  mutations: {
    setJobCategories(state, payload) {
      state.jobCategories = [...payload]
    },
    setJobCategory(state, payload) {
      state.jobCategory = {...payload}
    },
    setJobs(state, payload) {
      state.jobs = [...payload]
    },
    setJob(state, payload) {
      state.job = {...payload}
    },
    addJobCategory(state, payload) {
      state.jobCategories.push(payload);
    },
    updateJobCategory(state, payload) {
      let index = state.jobCategories.findIndex(el => el.id == payload.id);
      state.jobCategories.splice(index, 1, payload); 
    },
    removeJobCategory(state, payload) {
      let index = state.jobCategories.findIndex(el => el.id == payload);
      state.jobCategories.splice(index, 1);
    },
    removeJob(state, payload) {
      let index = state.jobs.findIndex(el => el.id == payload);
      state.jobs.splice(index, 1);
    }

  },

  actions: {
    getJobCategories({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/jobCategories`, {
          keywords: payload.keywords,
          page: payload.page,
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setJobCategories', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getJobs({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/jobs`, {
          keywords: payload.keywords,
          page: payload.page,
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setJobs', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getJobCategory({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/jobCategories/show/${payload}`, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setJobCategory', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    },
    getJob({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/jobs/show/${payload}`, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setJob', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    },
    createJobCategory({commit}, payload) {
      return new Promise ((resolve, reject) => {
        console.log(payload.base64)        
        Axios.post(`/admin/jobCategories/save`, {
          id: payload.id,
          titles: {
            ar: payload.titles.ar,
            en: payload.titles.ar,
          }, 
          parent_id: payload.parent_id,
          status: payload.status,
        })
        .then(res => {
          if(res.data.results != null) {
            if(payload.id) {
              commit('updateJobCategory', res.data.results);
            }else {
              commit('addJobCategory', res.data.results);
            }
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    createJob({commit}, payload) {
      return new Promise ((resolve, reject) => {
        console.log(payload.base64)        
        Axios.post(`/admin/jobs/save`, {
          id: payload.id,
          titles: {
            ar: payload.titles.ar,
            en: payload.titles.ar,
          }, 
          descriptions: {
            ar: payload.descriptions.ar,
            en: payload.descriptions.ar,
          }, 
          location: {
            ar: payload.location,
            en: payload.location,
          },
          job_category: payload.job_category,
          parent_id: payload.parent_id,
          ord: 0,
          status: payload.status,
          type: payload.type,
          experince_level: payload.experience_level,
          conatacts: payload.conatacts,
          salary_avrage: {
            from: payload.salary_from,
            to: payload.salary_to,
          },
          requirment: payload.requirment,
          Responsibilities: payload.Responsibilities,
          image_base64: payload.base64 ? {...payload.base64[0]} : undefined,
        })
        .then(res => {
          if(res.data.results != null) {
            if(payload.id) {
              commit('updateJobCategory', res.data.results);
            }else {
              commit('addJobCategory', res.data.results);
            }
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteJobCategory({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/jobCategories/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeJobCategory', payload);
            resolve(res);
          }else {
            reject(res)
        }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteJob({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/jobs/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeJob', payload);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    
  }
}