<template>
  <validation-observer ref="simpleRules">
     <div>
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="submit"
        v-if='items.length > 0'
      >
        <!-- Row Loop -->
        <b-row
          v-for="(item, itemInd) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Item Name -->
          <b-col>
            <label for='day'>
              {{$t('Day')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='day'
            >
              <b-form-select
                id="day"
                :options='days'
                v-model="item.day"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- {{item.titles}} -->
          <b-col>
            <label for='programTime'>
              {{$t('From Time')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='programTime'
            >
              <b-form-timepicker v-model='item.from' placeholder=' -- : --'/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col>
            <label for='programend'>
              {{$t('To Time')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='programend'
            >
              <b-form-timepicker v-model='item.to' placeholder=' -- : --'/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols='2'>
            <label for='repeatTime'>
              {{item.repeated ? $t('Repeat') : $t('Live')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules=""
              name='repeatTime'
            >
              <b-form-checkbox 
                v-model='item.repeated'
                switch
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="2"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(itemInd)"
            >
              <feather-icon
                v-if='item.delete != true'
                icon="XIcon"
                class="mr-25"
              />
              <span v-else>
                <!-- <feather-icon
                icon="XIcon"
                class="mr-25"
              /> -->
                {{$t('Deleted')}}
              </span>
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
      class="btn-icon my-1"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>{{$t('add new')}}</span>
    </b-button>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
    BFormCheckbox, BFormTextarea, BInputGroup, BInputGroupAppend,
    BFormDatepicker, BFormTimepicker, BFormSelect
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import {mapActions, mapGetters} from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox, BFormTextarea, BInputGroup, BInputGroupAppend,
    ValidationProvider, ValidationObserver,
    vSelect, BFormDatepicker, BFormTimepicker, BFormSelect
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
        // {
        //   day: 'Saturday',
        //   from: '',
        //   to: ''
        // },
        // {
        //   day: 'Sunday',
        //   from: '',
        //   to: ''
        // },
        // {
        //   day: 'Monday',
        //   from: '',
        //   to: ''
        // },
        // {
        //   day: 'Tuesday',
        //   from: '',
        //   to: ''
        // },
        // {
        //   day: 'Wednesday',
        //   from: '',
        //   to: ''
        // },
        // {
        //   day: 'Thursday',
        //   from: '',
        //   to: ''
        // },
        // {
        //   day: 'Friday',
        //   from: '',
        //   to: ''
        // },
      ],
      defaultItem: {
        day: 'Sunday',
        from: '',
        to: '',
        repeated: false
      },
      formLoading: false,
      tempitems: [],
      
      required,
    }
  },
  props: ['getRepeaterData', 'editObj'],
  watch: {
    items: function() {
      if (this.items.length == 0) {
        this.repeateAgain();
      }
    },
  },  
  mounted() {
    this.formLoading = true;
    if(this.editObj != null) {
      this.items = [...this.editObj.program_times];
      this.$forceUpdate();
    }else {
      this.formLoading = false;
      this.repeateAgain()
    }
    // this.formLoading = false;
  },
  methods: {
    ...mapActions(['getProducts']),
    repeateAgain() {
      // let newId = this.createItemId(); 
      this.items.push({
        ...this.defaultItem,
        // id: newId,
      })
    },
    submit() {
      return this.$refs.simpleRules.validate()
        .then(res => {
          this.valid = res;
          if (res == true) {
            let deleted_times = [];
            let items = this.items.map(el => {
              if(el.delete == true) {
                deleted_times.push(el.id)
              }
              return el = {
                ...el,
                from: this.formateTime(el.from),
                to: this.formateTime(el.to),
                repeated: el.repeated == true ? true : false,  
              }
            })
            
            console.log(this.items)
            this.getRepeaterData({
              items,
              deleted_times,
            })
            return true;
          }else {
            return false;
          }
      })
    },
    removeItem(index) {
      // this.items.splice(index, 1)
      if(this.items[index].delete == true) {
        this.items[index].delete = false;
      }else {
        this.items[index].delete = true;
      } 
      console.log(this.items)
      this.$forceUpdate()
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    changeNameLang(item) {
      if(item.nameLang == 'ar') {
        item.nameLang = 'en'
      }else {
        item.nameLang = 'ar'
      }
    },
    checkEmptyLocale(item) {
      console.log(item)
      if(!item.ar || !item.en) {
        if(!item.ar) {
          item.ar = item.en;  
        }else {
          item.en = item.ar;
        }
      }
      item.titles.ar = item.ar;
      item.titles.en = item.en;
    },
    changeQuantityType(item) {
      console.log(item)
      if(item.quantity == -1) {
        item.quantity = 1;
      }else {
        item.quantity = -1;
      }
    },
    searchProducts(value) {
      this.getProducts({
        keywords: value,
      })
    },
    setProductItems(index, item) {
      item.item_id = item.product.items[0].id;
      item.product_id = item.product.id;
      this.$forceUpdate();
      // console.log(index, item);
      // console.log(this.items);
    },
    getFormatedDate(item) {
      console.log(item.expire_date);
      // this.formateDateShort2(item.expire_date);
    } 
  },
  computed: {
    ...mapGetters(['products']),
    days() {
      return [
        'Saturday',
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',

      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.repeater-form {
  padding-bottom: 150px
}
</style>