import Axios from '../../libs/strataIns';

export default {
  state: {
    contactus: [],
    // offer: {},

    // ordersStatus: [],
    // orderStatus: {},
  },

  getters: {
    contactus: (state) => state.contactus,
    // offer: (state) => state.offer,
    // ordersStatus: (state) => state.ordersStatus,
    // orderStatus: (state) => state.orderStatus,
  },

  mutations: {
    setContactus(state, payload) {
      state.contactus = [...payload];
    },
    removeContactus(state, payload) {
      let index = state.contactus.findIndex(el => el.id == payload);
      state.contactus.splice(index, 1);
    }
  },

  actions: {
    getContactUs({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/contactus/list`, {
          id: payload.id,
          keywords: payload.keywords,
          categorys_id: payload.driver_id,
          status: payload.status,
          page: payload.page,
        })
          .then(res => {
            if (res.data.results != null) {
              commit('setContactus', res.data.results.data);
              resolve(res);
            } else {
              reject(res);
            }
          }).catch(err => {
            reject(err);
          });
      });
    },
    deleteContactUs({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`admin/contactus/delete/${payload}`, {})
          .then(res => {
            if (res.data.results != null) {
              commit('removeContactus', payload);
              resolve(res);
            } else {
              reject(res);
            }
          }).catch(err => {
            reject(err);
          });
      });
    },

  }
};