var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"start"}},[_vm._v(" "+_vm._s(_vm.$t('Start Row'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"start"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"start","type":"number"},model:{value:(_vm.options.start),callback:function ($$v) {_vm.$set(_vm.options, "start", $$v)},expression:"options.start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"code"}},[_vm._v(" "+_vm._s(_vm.$t('Code Column'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code"},model:{value:(_vm.options.barcode),callback:function ($$v) {_vm.$set(_vm.options, "barcode", $$v)},expression:"options.barcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"category"}},[_vm._v(" "+_vm._s(_vm.$t('Category Column'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"category"},model:{value:(_vm.options.category),callback:function ($$v) {_vm.$set(_vm.options, "category", $$v)},expression:"options.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"arname"}},[_vm._v(" "+_vm._s(_vm.$t('Arabic Name Column'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"arname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"start"},model:{value:(_vm.options.name.ar),callback:function ($$v) {_vm.$set(_vm.options.name, "ar", $$v)},expression:"options.name.ar"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"code"}},[_vm._v(" "+_vm._s(_vm.$t('English Name Column'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code"},model:{value:(_vm.options.name.en),callback:function ($$v) {_vm.$set(_vm.options.name, "en", $$v)},expression:"options.name.en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"price"}},[_vm._v(" "+_vm._s(_vm.$t('Price Column'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price"},model:{value:(_vm.options.price),callback:function ($$v) {_vm.$set(_vm.options, "price", $$v)},expression:"options.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"image"}},[_vm._v(" "+_vm._s(_vm.$t('Image Path Column'))+" ")]),_c('validation-provider',{attrs:{"name":"image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"start"},model:{value:(_vm.options.image_path),callback:function ($$v) {_vm.$set(_vm.options, "image_path", $$v)},expression:"options.image_path"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"quantity"}},[_vm._v(" "+_vm._s(_vm.$t('Quantity Column'))+" ")]),_c('validation-provider',{attrs:{"name":"quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"code"},model:{value:(_vm.options.quantity),callback:function ($$v) {_vm.$set(_vm.options, "quantity", $$v)},expression:"options.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('ImportFileForm',{ref:"fileForm",attrs:{"getFileData":_vm.getFileData}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }