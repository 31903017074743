<template>
  <div class="" v-if='!formLoading'>
    <BaseForm 
      v-if='formObj != null && !formLoading' 
      :editObj='formObj' 
      :getBaseData='getBaseData' 
      ref='baseForm'
      :noDescription="true"
    />
    <hr/>
    <ResponsiveFieldsForm 
      v-if='formObj != null && !formLoading' 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
    />
    <!-- <hr/> -->
    <!-- <ImgForm 
      :editObj='formObj' 
      :getMediaData='getMediaData'
      :getImgData='getImgData' 
      ref='imgForm'
    /> -->
    
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';

import {
  BRow, BCol
} from 'bootstrap-vue';

import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    ResponsiveFieldsForm,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow, BCol
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        // color: '#FFFFFF',
        status: true,
      }
    }
  },

  props: ['editObj', 'closeModal',],
  mounted() {
    console.log(this.editObj)
    if(this.editObj != null) {
      if(this.jobCategoryRoute) {
        this.getJobCategory(this.editObj.id)
          .then(res => {
            this.formObj = res.data.results;
            this.formObj.title = this.formObj.titles.en;
            this.formData.id = this.editObj.id;
            setTimeout(() => {
              this.formLoading = false;
            }, 100)
          })

      }else if(this.categoryRoute) {
        this.getCategory(this.editObj.id)
          .then(res => {
            this.formObj = res.data.results;
            this.formData.id = this.editObj.id;
            setTimeout(() => {
              this.formLoading = false;
            }, 100)
          })
      }
    }else {
      this.formObj = {
        status: true
      }
    }
  },
  methods: {
    ...mapActions(['getPages', 'getCategories', 'getJobCategories', 'createCategory', 'createJobCategory', 'getCategory', 'getJobCategory']),
    formSubmit()  {
      this.$refs.restForm.submit()
        .then(res => {
          if(res == true) {
            if(res == true) {
              this.$store.commit('setModalLoading', true);
              this.$refs.restForm.submit();
              // this.$refs.imgForm.submit();
              this.$refs.baseForm.submit();
              console.log(this.formData)
              if(this.jobCategoryRoute) {
                setTimeout(() => {
                  this.createJobCategory(this.formData)
                    .then(res => {
                      this.closeModal();
                      this.getJobCategories({});
                      this.$store.commit('setModalLoading', false);
                    })
                }, 100)

              }else {
                setTimeout(() => {
                  this.createCategory(this.formData)
                    .then(res => {
                      this.closeModal();
                      this.getCategories({});
                      this.$store.commit('setModalLoading', false);
                    })
                }, 100)
              }
            }
          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      console.log(data)
      this.formData.base64 = [];
      if(this.editObj && this.editObj.image) {
        this.formData.base64 = [
          {
            ...this.editObj.image,
            delete: true
          }
        ]
      }
        
      this.formData.base64 = [
        ...this.formData.base64,
        data,
      ];
    },
      
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getMediaData(data) {
      if(this.isFileImage(data)) {
        this.formData.media_type = 0;
      }else {
        this.formData.media_type = 1;
      }
      this.formData.base64 = data;
    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if(this.formData.descriptions.ar == null || this.formData.descriptions.en == null) {
        if(this.formData.descriptions.ar == null) {
          this.formData.descriptions.ar = ''
        }
        if(this.formData.descriptions.en == null) {
          this.formData.descriptions.en = ''
        }
      }
    },
    searchCategories(searchText) {
      this.getCategories({
        keywords: searchText,
        status: 1,
      })
    }
  },

  computed: {
    ...mapGetters(['product', 'modalLoading', 'pages', 'categories']),
    filteredCategories() {
      return this.categories.filter(el => !el.parent_id || el.parent_id == null)
    },
    categoryRoute() {
      return this.$route.name == 'Categories';
    },
    jobCategoryRoute() {
      return this.$route.name == 'JobCategories';
    },
    restFields() {
      if(this.categoryRoute) {
        return [
          {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
          // {
          //   name: 'parent', type: 'searchSelect', rules: 'required', 
          //   model: 'parent_id', options: this.filteredCategories, label: 'title', 
          //   searchItems: this.searchCategories,
          //   cols: 12,
          // },
        ]
      }else {
        return [
          {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
        ]
      }
      
    }
  }

}
</script>