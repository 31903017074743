<template>
  <b-card no-body class="invoice-preview-card" v-if='editObj && settings'>
    <!-- Header -->
    <b-card-body class="invoice-padding pb-0">
      <div
        class="
          d-flex
          justify-content-between
          flex-md-row flex-column
          invoice-spacing
          align-items-center
          mt-0
        "
      >
        <div class="logo-wrapper">
          <h3 class="text-primary invoice-logo">
            <img src="@/assets/images/logo/NoPath.png" width="130px" />
          </h3>
        </div>
        <div class="title-wrapper">
          <h2 class="">فاتورة</h2>
        </div>
      </div>
      <hr />
    </b-card-body>

    <div
      class="
        bill-main-info
        d-sm-flex d-block
        flex-row-reverse
        align-items-center
      "
    >
      <div class="sooq-contact-info px-2">
        <p v-for="(info, i) in contactInfo" :key="i">{{ info }}</p>
      </div>
      <div class="bill-info">
        <p v-for="(info, i) in billInfo" :key="i">
          <span> {{ info.title }}: </span>
          <span v-if="info.content">
            {{ info.content }}
          </span>
          <img width="150px" :src="info.src" alt="BarCode" v-if="info.src" />
        </p>
      </div>
    </div>

    <!-- Invoice Description: Table -->
    <div class="table-container p-1" v-if='editObj'>
      <b-table
        :striped="false"
        :bordered="true"
        :outlined="true"
        :hover="true"
        :items="infoTableItems"
        :fields="infoTableFields"
        class="text-right"
      >
        <template #cell(billTo)="data">
          <span class="text-right" dir="rtl" v-html="data.item.billTo"></span>
        </template>
        <template #cell(chargeTo)="data">
          <span class="text-right" dir="rtl" v-html="data.item.chargeTo"></span>
        </template>
      </b-table>
    </div>
    <div class="table-container px-1" v-if='editObj'>
      <b-table
        :striped="false"
        :bordered="true"
        :outlined="true"
        :hover="true"
        :items="editObj.products"
        :fields="fields"
        class="text-right"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
      </b-table>
    </div>
    <div class="table-container px-1">
      <b-table
        :striped="false"
        :bordered="true"
        thead-class="d-none"
        :outlined="true"
        :hover="true"
        :items="drivingTableItmes"
        :fields="drivingTableFileds"
        class="text-right"
      ></b-table>
    </div>
    <hr class="invoice-spacing" />
    <div class="notes text-right px-2" style='direction: rtl'>

      <p><span>المنشئ : </span> {{ user.user.name }}</p>
      <p><span>تاريخ الانشاء : </span> {{ fomateDateShort(new Date())  }}</p>
      <p><span>ملاحظات : </span> {{ editObj.notes || ' - ' }}</p>
    </div>
  </b-card>
</template>


<script>
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTable,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
  BFormSelect,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { title } from "@/@core/utils/filter";
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormSelect,
    BForm,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      infoTableFields: [
        {
          key: "chargeTo",
          label: this.$t("التوصيل إلي"),
        },
        {
          key: "billTo",
          label: this.$t("الفاتورة إلي"),
        },
      ],

      fields: [
        {
          key: "pivot.total",
          label: this.$t("الاجمالي"),
        },
        {
          key: "pivot.quantity",
          label: this.$t("الكميه"),
        },
        {
          key: "items[0].price",
          label: this.$t("السعر"),
        },

        {
          key: "title",
          label: this.$t("المنتجات"),
        },
        {
          key: "id",
          label: this.$t("كود المنتج"),
        },
        {
          key: "index",
          label: this.$t("التسلسل"),
        },
      ],
      drivingTableFileds: ["content", "title"],

    
      payOptions: [
        { id: 1, text: this.$t("paid"), value: true },
        { id: 2, text: this.$t("not paid"), value: false },
      ],
      statusData: {
        payment: false,
        delivery_id: 0,
        driver_id: null,
        seller_id: null,
      },
      tableVariants: [
        "primary",
        "secondary",
        "info",
        "danger",
        "warning",
        "success",
        "light",
        "dark",
      ],
    };
  },
  props: ["editObj", "closeModal"],
  mounted() {
    this.getSettings();
    this.getOrdersStatus({});
    this.statusData.payment =
      this.editObj.payments_status == true ? true : false;
    this.statusData.delivery_id = this.editObj.status_id;
    if (this.editObj.driver) {
      this.statusData.driver_id = this.editObj.driver.id;
    }
    if (this.editObj.seller) {
      this.statusData.seller_id = this.editObj.seller.id;
    }
  },
  methods: {
    ...mapActions([
      "getSettings",
      "getOrdersStatus",
      "updateOrder",
      "getOrders",
      "getFilteredUsers",
    ]),
    formSubmit() {
      // this.updateOrder({
      //   id: this.editObj.id,
      //   status_id: this.statusData.delivery_id,
      //   paid: this.statusData.payment,
      //   driver_id: this.statusData.driver_id,
      //   seller_id: this.statusData.seller_id,
      // }).then((res) => {
      //   console.log(res);
      //   this.closeModal();
      //   this.getOrders({});
      // });
      window.print()
    },
    searchSellers(searchText) {
      this.getFilteredUsers({
        keywords: searchText,
        perm: "users.seller",
      });
    },
    searchDrivers(searchText) {
      this.getFilteredUsers({
        keywords: searchText,
        perm: "users.driver",
      });
    },
  },

  computed: {
    ...mapGetters([
      "settings",
      "ordersStatus",
      "filteredUsers",
      "filteredSellers",
      'user'
    ]),
    contactInfo(){
      return [
        "سوق جنان",
        "الهاتف: 0097433006262",
        "jinansooq@gmail.com",
        "https://sooqjinan.com",
        `  ${this.editObj.seller ? this.editObj.seller.name : "-"} :البائع`,
        `${this.editObj.driver ? this.editObj.driver.name : "-"} :السائق `,
      ]
    },
    billInfo() {
      return [
        {
          title: "توقيت الطلب",
          content: ` ${this.editObj.created_at
            .replace("T", ` | ${this.editObj.recurring.at}`)
            .replace(".000000Z", ``)}`,
        },
        { title: "رقم الطلب", content: this.editObj.id },
        {
          title: "طريقة الدفع",
          content: `${
            this.editObj.payment_method ? this.editObj.payment_method : "-"
          }`,
        },
        { title: "طريقة الشحن", content: "سعر شحن مخصص ثابت" },
      ]
    },
    drivingTableItmes() {
      return [
        {
          title: "سعر المنتجات",
          content:
            this.editObj.total -
            this.editObj.fees.shipping.value
        },
        {
          title: "تكلفة التوصيل",
          content: this.editObj.fees.shipping.value
        },
        {
          title: "الاجمالي  ",
          content: this.editObj.total,
        },
      ];
    },
    weekDays() {
      return [
        "الاحد",
        "الاثنين",
        "الثلاثاء",
        "الاربعاء",
        "الخميس",
        "الجمعه",
        "السبت",
      ];
    },
    infoTableItems() {
      return [
        {
          billTo: `
           اسم العميل: ${this.editObj.address.name} 

          <br/>
           البريد االلكتروني: ${
             this.editObj.address.email ? this.editObj.address.email : "-"
           }
           <br/>
            الهاتف: ${this.editObj.address.phone}`,

          chargeTo: `
          رقم المنطقه: ${this.editObj.address.area_no} - رقم الشارع: ${
            this.editObj.address.street
          } - رقم المبني: ${this.editObj.address.home_no}  <br/> التوصيل: ${
            this.weekDays[this.editObj.recurring.day]
          } - ${this.editObj.recurring.at == "am" ? "صباحا" : ""}${
            this.editObj.recurring.at == "an" ? "عصرا" : ""
          }${this.editObj.recurring.at == "bm" ? "مساء" : ""}
          `,
        },
      ];
    },
  },
};
</script>




<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.title-wrapper {
  h2 {
    font-size: 40px;
    color: #000;
  }
}
.hidden_header {
  display: none;
}
.sooq-contact-info {
  width: 67%;
  @media (max-width: 575px) {
    width: 100%;
  }
}
.sooq-contact-info,
.bill-info {
  p {
    text-align: right;
    font-weight: bold;
    margin: 0;
    font-size: 12px;
  }
}
.bill-info {
  direction: rtl;
  margin-right: 150px;
  @media (max-width: 992px) {
    margin: 10px;
  }
  @media (max-width: 575px) {
    margin: 10px 0;
    padding: 0 1.5rem;
  }
  width: 100%;
  span {
    display: inline-block;
    width: 130px;
    @media (max-width: 992px) {
      width: 80px;
    }
    &:last-child {
      margin-right: 20px;
      width: auto !important;
    }
  }
  img {
    margin-right: 15px;
  }
}
.notes {
  margin: 20px 0;
  p {
    font-size: 22px;
    span {
      font-weight: bold;
    }
  }
}
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }

  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .search-area-not-printed {
    display: none;
  }
}
</style>
