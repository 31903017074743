<template>
  <div class="" v-if='!formLoading'>
    <BaseForm 
      :editObj='formObj' 
      :getBaseData='getBaseData' 
      ref='baseForm'
    />
    <hr/>
    <ResponsiveFieldsForm 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
    />
    <!-- <hr/> -->
    <!-- <ProductRestForm :editObj='formObj' :getRestData='getRestData' ref='restForm'/> -->
    <!-- <hr/> -->
    <ImgForm 
      :editObj='formObj' 
      :getImgData='getImgData' 
      ref='imgForm'
    />
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';

import {
  BRow, BCol
} from 'bootstrap-vue';

import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    ResponsiveFieldsForm,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow, BCol
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        status: true,
      }
    }
  },

  props: ['editObj', 'closeModal',],
  mounted() {
    if(this.editObj != null) {
      this.formLoading = true
      this.getTestmonial(this.editObj.id)
        .then(res => {
          this.formObj = res.data.results;
          console.log(this.formObj)
          this.formData.id = this.editObj.id;
          setTimeout(() =>{
            this.formLoading = false;
          }, 100)
        })
    }
  },
  methods: {
    ...mapActions(['getTestmonial', 'getTestmonials', 'createTestmonial']),
    formSubmit()  {
      this.$refs.baseForm.submit()
        .then(res => {
          if(res == true) {
            
            this.$refs.restForm.submit()
            .then(res => {
              if(res == true) {
                this.$store.commit('setModalLoading', true);
                this.$refs.restForm.submit();
                this.$refs.baseForm.submit();
                this.$refs.imgForm.submit();
                console.log(this.formData)
                // this.checkNulldesc();
                setTimeout(() => {
                  this.createTestmonial(this.formData)
                    .then(res => {
                      this.closeModal();
                      this.getTestmonials({});
                      this.$store.commit('setModalLoading', false);
                    })
                }, 100)
              }
            })

          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      console.log(data)
      this.formData.base64 = [];
      // if(this.editObj && this.editObj.image) {
      //   this.formData.base64 = [
      //     {
      //       ...this.editObj.image,
      //       delete: true
      //     }
      //   ]
      // }
        
      this.formData.base64 = [
        ...this.formData.base64,
        data,
      ];
      console.log(this.formData.base64)
    },
      
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if(this.formData.descriptions.ar == null || this.formData.descriptions.en == null) {
        if(this.formData.descriptions.ar == null) {
          this.formData.descriptions.ar = ''
        }
        if(this.formData.descriptions.en == null) {
          this.formData.descriptions.en = ''
        }
      }
    }
  },

  computed: {
    ...mapGetters(['product', 'modalLoading']),
    restFields() {
      return [
        {name: 'info', type: 'text', rules: 'required', model: 'info', cols:12 },
        {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
      ]
    }
  }

}
</script>