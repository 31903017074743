<template>
  <b-card
      no-body
    >
      <b-card-header>
        <b-card-title>{{$t($route.name)}}</b-card-title>
         <b-button
              class=""
              variant='success'
              @click='openModal(null, "Create")'
              v-if='resolvePermission("category.create")'
            >
            <feather-icon
              icon="PlusIcon"
              size="15"
            >

            </feather-icon>
            {{$t('add new')}}
          </b-button>
      </b-card-header>
      <b-row class="mx-2">
        <b-col>
          <b-form-group
            :label='$t("Search")'
            label-for="basicInput"
          >
            <b-form-input
              id="basicInput"
              v-model='searchText'
            />
          </b-form-group>
        </b-col>
        <!-- <b-col>
          <b-form-group
            label="Basic Input"
            label-for="selectInput"
          >
            <b-form-select
              id='selectInput'
              v-model="selected"
              :options="options"
            />
            <v-select
              v-model="selected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="options"
            />
          </b-form-group>
        </b-col> -->
        <b-col cols='1' class="align-self-center">
          <b-button 
            variant='primary'
            class="btn my-auto"
            :disabled ='loading'
            @click="filterItems"
          >
            <feather-icon
              icon='SearchIcon'
              size='16'
            ></feather-icon>
          </b-button>
        </b-col>
      </b-row>

      
    <b-table
      responsive
      :items="categories"
      :fields="fields"
      class="mb-0"
    >
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>

      <template #cell(image)="data">
        <img :src='data.item.icon_url ? data.item.icon_url : placeHolder' width="40" height="38" />
      </template>
      <template #cell(actions)="row">
          <!-- <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button> -->
          <b-button 
            size="sm" 
            @click="editItem(row)"
            variant="flat-dark"  
            class="btn-icon"
            v-if='resolvePermission("category.edit")'
          >
            <feather-icon
              icon="Edit2Icon"
              size="15"
            />
          </b-button>
          <b-button 
            size="sm" 
            @click="deleteItem(row)"
            variant="flat-danger"  
            class="btn-icon"
            v-if='resolvePermission("category.delete")'
          >
            <feather-icon
              icon="Trash2Icon"
              size="15"
            />
          </b-button>
          <feather-icon
            v-if="row.item.id == 11 || row.item.id == 12"
            icon="KeyIcon"
            size="15"
          />
        </template>
    </b-table>

    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @change="filterItems"
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import { 
  BTable, BButton, BRow, BCol , 
  BCard, BCardTitle, BCardHeader, BCardFooter, 
  BFormGroup, BFormInput, BFormSelect,
  BPagination,
} from 'bootstrap-vue';
import vSelect from 'vue-select'

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { mapActions, mapGetters } from 'vuex';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    FeatherIcon,
    BCard, BCardTitle, BCardHeader, BCardFooter,
    BFormGroup, BFormInput, BFormSelect, vSelect,
    BPagination,
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: this.$t('ID')
        },
        // {
        //   key: 'image',
        //   label: ''
        // },
        {
          key: 'title',
          label: this.$t('title')
        },
        // {
        //   key: 'parent_id',
        //   label: this.$t('Parent'),
        //   formatter: (value, key, item) => value ? value : '-'  
        // },
        {
          key: 'childs_count',
          label: this.$t('Projects Count'),
          formatter: (value, key, item) => value ? value : '-'  
        },
        // {
        //   key: 'created_at',
        //   label: 'date',
        //   formatter: (value, key, item) => this.formateDate(item),
        // },
        {
          key: 'actions',
          label: this.$t('actions')
        },
      ],
      searchText: '',
      options: [],
      selected: '',
      currentPage: 1,
      perPage: 10,
      rows: 0,
    }
  },
  props: ['openModal', 'closeModal', 'confirmDelete',],
  created() {
    this.getCategories({})
      .then(res => {
        // console.log(res)
        this.rows = res.data.meta.total
      }).catch(err => console.error(err))
  },

  methods: {
    ...mapActions(['getCategories', 'deleteCategory', 'createCategory', 'getCategory']),
    filterItems() {
      setTimeout(() => {
        this.getCategories({
          page: this.currentPage,
          keywords: this.searchText
        }).then(res => {
  
        }).catch(err => {
  
        })
      }, 100)
    },

    showItem(row) {
      this.openModal(row, 'Show')
      // console.log(row)
    },
    
    editItem(row) {
      this.openModal(row, 'Edit')
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteCategory, row)
    }
  },

  computed: {
    ...mapGetters(['categories', 'loading' ]),
  }
}
</script>