import Axios from '../../libs/nabdIns';

export default {
  state: {
    shows: [],
    show: {},
  },

  getters: {
    shows: (state) => state.shows,
    show: (state) => state.show,
  },

  mutations: {
    setShows(state, payload) {
      state.shows = [...payload]
    },
    setShow(state, payload) {
      state.show = {...payload}
    },
    removeShow(state, payload) {
      let index = state.shows.findIndex(el => el.id == payload);
      state.shows.splice(index, 1);
    }
  },

  actions: {
    getShows({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/shows`, {
          today: payload.today,
          day: payload.day,
          page: payload.page,
          stauts: payload.stauts,
          keywords: payload.keywords
        }, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setShows', res.data.results.data);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getShow({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/shows/show/${payload}`, {}, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setShow', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    createShow({commit}, payload) {
      console.log(payload)
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/shows/save`, {
          id: payload.id,
          titles: payload.titles,
          descriptions: payload.descriptions,
          times: payload.times,
          deleted_times: payload.deleted_times,
          social_links: {
            facebook: payload.facebook,
            twitter: payload.twitter,
            instagram: payload.instagram,
            whatsapp: payload.whatsapp,
            youtube: payload.youtube,
          },
          announcers_id: payload.announcers_id.length ? payload.announcers_id : [payload.announcers_id] ,
          cover_image_base64: payload.cover_image_base64,
          player_image_base64: payload.player_image_base64,
        })
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteShow({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/shows/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeShow', payload);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
  }
}