import { password } from '@/@core/utils/validations/validations';
import Axios from '../../libs/nabdIns';

export default {
  state: {
    user: null,
    loading: false,
    modalLoading: false,
    statsLoading: false,
  },
  
  getters: {
    user: (state) => state.user,
    loading: (state) => state.loading,
    modalLoading: (state) => state.modalLoading,
    statsLoading: (state) => state.statsLoading,
    
  },

  mutations: {
    setUser(state, payload) {
      state.user = {...payload};
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setModalLoading(state, payload) {
      state.modalLoading = payload;
    },
    setStatsLoading(state, payload) {
      state.statsLoading = payload;
    },

  },

  actions: {
    userLogin({commit, }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/user/auth`, {}, {
          params: {
            email: payload.email,
            password: payload.password,
          }
        }).then(res => {
          if(res.data.results != null) {
            commit('setUser', res.data.results);
            resolve(res);
          }else {
            reject(res);
          }
        }).catch(err => {
          reject(err);
        })
      })
    },
    userLogout({commit, }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/user/logout`, {}, {})
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res);
          }
        }).catch(err => {
          reject(err);
        })
      })
    },
    getUserProfile({commit, }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/user/profile`, {}, {
          // params: {
          // }
        }).then(res => {
          if(res.data.results != null) {
            // commit('setUser', res.data.results);
            resolve(res);
          }else {
            reject(res);
          }
        }).catch(err => {
          reject(err);
        })
      })
    },
    UserProfileUpdate({commit, }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/user/profile/update`, {}, {
          params: {
            name: payload.name,
            email: payload.email,
            password: payload.password,
            current_password: payload.current_password,
            password_confirmation: payload.password_confirmation,
          }
        }).then(res => {
          if(res.data.results != null) {
            commit('setUser', res.data.results);
          }else {
            reject(res);
          }
        }).catch(err => {
          reject(err);
        })
      })
    },

  }
}