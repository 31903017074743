<template>
  <div class="" v-if='!formLoading'>
    <BaseForm :editObj='formObj' :getBaseData='getBaseData' ref='baseForm' />
    <hr/>
    <ItemRepeater :editObj='formObj' :getRepeaterData='getRepeaterData' ref='repeaterForm' />
    <hr/>
    <ProductRestForm :editObj='formObj' :getRestData='getRestData' ref='restForm'/>
    <hr/>
    <ImgForm :editObj='formObj' :getImgData='getImgData' ref='imgForm'/>
  </div>
</template>

<script>
import ItemRepeater from './ItemRepeater.vue'
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';

import {
  BRow, BCol
} from 'bootstrap-vue';

import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    ItemRepeater,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow, BCol
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
    }
  },

  props: ['editObj', 'closeModal',],
  mounted() {
    if(this.editObj != null) {
      this.formLoading = true
      this.getProduct(this.editObj.id)
        .then(res => {
          this.formObj = res.data.results;
          this.formData.id = this.editObj.id;
          this.formLoading = false;
        })
    }
  },
  methods: {
    ...mapActions(['getProduct', 'getProducts', 'createProduct']),
    formSubmit()  {
      this.$refs.baseForm.submit()
        .then(res => {
          if(res == true) {
            
            this.$refs.repeaterForm.submit()
            .then(res => {
              if(res == true) {
                this.$store.commit('setModalLoading', true);
                this.$refs.baseForm.submit();
                this.$refs.repeaterForm.submit();
                this.$refs.restForm.submit();
                this.$refs.imgForm.submit();
                console.log(this.formData)
                this.checkNulldesc();
                setTimeout(() => {
                  this.createProduct(this.formData)
                    .then(res => {
                      this.closeModal();
                      this.getProducts({});
                      this.$store.commit('setModalLoading', false);
                    })
                }, 100)
              }
            })

          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      this.formData.base64 = [];
      if(this.editObj.image) {
        this.formData.base64 = [
          {
            ...this.editObj.image,
            delete: true
          }
        ]
      }
        
      this.formData.base64 = [
        ...this.formData.base64,
        data,
      ]
    },
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if(this.formData.descriptions.ar == null || this.formData.descriptions.en == null) {
        if(this.formData.descriptions.ar == null) {
          this.formData.descriptions.ar = ''
        }
        if(this.formData.descriptions.en == null) {
          this.formData.descriptions.en = ''
        }
      }
    }
  },

  computed: {
    ...mapGetters(['product', 'modalLoading']),
  }

}
</script>