import Axios from '../../libs/strataIns';

export default {
  state: {
    subscribtions: [],
    // offer: {},
    
    // ordersStatus: [],
    // orderStatus: {},
  },

  getters: {
    subscribtions: (state) => state.subscribtions,
    // offer: (state) => state.offer,
    // ordersStatus: (state) => state.ordersStatus,
    // orderStatus: (state) => state.orderStatus,
  },

  mutations: {
    setSubscribtions(state, payload) {
      state.subscribtions = [...payload]
    },
    removeSubscribtion(state, payload) {
      let index = state.subscribtions.findIndex(el => el.id == payload);
      state.subscribtions.splice(index, 1);
    }
  },

  actions: {
    getSubscribtions({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/subscribe/list`, {
          id: payload.id,
          keywords: payload.keywords,
          categorys_id: payload.driver_id,
          status: payload.status,
          page: payload.page,
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setSubscribtions', res.data.results.data);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteSubscribtion({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`admin/subscribe/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeSubscribtion', payload);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    
  }
}