<template>
  <b-form @submit.prevent="submit" >
    <b-row>
      <b-col
        cols='4' 
        v-for='permission in allPermissions'
        :key='permission.key'
        v-show='permission.key != "accounts"'
      >
        <!-- {{permission}} -->
        <h4 v-if='keyNeededPerms(permission) != true'>
          {{permission.key}}
        </h4>
        <b-table
          responsive
          :items="permission.permissions"
          :fields="permissionsFields"
          class="my-2"
          outlined
          small
        >
          <template #cell(truth)="data">
            <!-- {{ data.item.permission }} -->
            <b-form-checkbox
              v-model='permissionsData[data.item.permission]'
            ></b-form-checkbox>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BButton, BMedia,
  BAvatar, BRow, BCol, BFormGroup, BFormInput, 
  BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  data() {
    return {
      permissionsData: {}
    }
  },
  props: ['editObj', 'getPermissionsData'],
  mounted() {
    if(this.editObj != null) {
      let perms = {}
      for (const [key, value] of Object.entries(this.editObj.permissions)) {
        perms[value] = true
      }
      this.permissionsData = {...perms}
    }
  },
  methods: {
    ...mapActions(['getRoles',]),
    submit() {
      let perms = [];
      // console.log(this.permissionsData)
      for (const [key, value] of Object.entries(this.permissionsData)) {
        if(value == true) {
          perms.push(key)
        }
      }
      this.getPermissionsData(perms)
    },
    keyNeededPerms(permission) {
      let keys = [
        'accounts'
      ]
      console.log(permission)
      return keys.includes(permission.key) ? true : false
    }
  },
  computed: {
    ...mapGetters(['userProfile', 'filteredUser', 'permissions', 'roles']),
    allPermissions() {
      return Object.values(this.permissions)
    },
    permissionsFields() {
      return [
        {
          key: 'name',
          label: this.$t('title')
        },
        // {
        //   key: 'permission',
        //   label: this.$t('permission')
        // },
        {
          key: 'truth',
        }
      ]
    },
  }
}
</script>