<template>
  <div class="" v-if='!formLoading'>
    <BaseForm 
      :editObj='formObj' 
      :getBaseData='getBaseData' 
      ref='baseForm'
    />
    <hr/>
    <ResponsiveFieldsForm 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
    />
    <!-- <hr/> -->
    <DaysRepeater :editObj='formObj' :getRepeaterData='getRepeaterData' ref='daysRepeaterForm'/>
    <!-- <hr/> -->
    <ImgForm 
      :editObj='formObj' 
      :getImgData='getImgData' 
      ref='coverimgForm'
      title='Cover'
    />
    <ImgForm 
      :editObj='formObj' 
      :getImgData='getImgData' 
      ref='playerimgForm'
      title='Player'
    />
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';
import DaysRepeater from './DaysRepeater.vue';

import {
  BRow, BCol
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    ResponsiveFieldsForm,
    DaysRepeater,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow, BCol
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        color: '#FFFFFF',
        status: true,
      }
    }
  },

  props: ['editObj', 'closeModal',],
  mounted() {
    console.log(this.editObj)
    this.getPresenters({
      limit: 100,
    });
    if(this.editObj != null) {
      this.formLoading = true
      this.getShow(this.editObj.id).then(res => {
        this.formObj = res.data.results;
        this.formObj.announcers_id = this.formObj.announcers_id.filter(item => item != null);

        if(this.editObj.social_links) {
          this.formObj.facebook = this.editObj.social_links.facebook;
          this.formObj.twitter = this.editObj.social_links.twitter;
          this.formObj.instagram = this.editObj.social_links.instagram;
          this.formObj.youtube = this.editObj.social_links.youtube;
          this.formObj.whatsapp = this.editObj.social_links.whatsapp;
        }

        setTimeout(() => {
          this.formLoading = false;
        }, 100)
        this.$forceUpdate();
      })
      // this.getLabel(this.editObj.id)
      //   .then(res => {
      //     this.formData.id = this.editObj.id;
      //     this.formLoading = false;
      //   })
    }
  },
  methods: {
    ...mapActions(['getShows', 'getPresenters', 'getShow', 'createShow']),
    formSubmit()  {
      this.$refs.baseForm.submit()
        .then(res => {
          if(res == true) {
            
            this.$refs.restForm.submit()
            .then(res => {
              if(res == true) {
                this.$store.commit('setModalLoading', true);
                this.$refs.restForm.submit();
                this.$refs.baseForm.submit();
                this.$refs.daysRepeaterForm.submit();
                this.$refs.coverimgForm.submit();
                this.$refs.playerimgForm.submit();
                console.log(this.formData)
                this.checkNulldesc();
                setTimeout(() => {
                  this.createShow(this.formData)
                    .then(res => {
                      this.closeModal();
                      this.getShows({});
                      this.$store.commit('setModalLoading', false);
                    }).catch(err => {
                      this.$store.commit('setModalLoading', false);
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: this.$t(err.response.data.results.message),
                          icon: 'XIcon',
                          variant: 'danger',
                        },
                      });
                    })
                }, 100)
              }
            })

          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data, key) {
      console.log(data)
      this.formData.base64 = [];
      if(this.editObj && this.editObj.image) {
        this.formData.base64 = [
          {
            ...this.editObj.image,
            delete: true
          }
        ]
      }
      if(key == 'Cover') {
        this.formData.cover_image_base64 = data;
      }else if(key == 'Player'){
        this.formData.player_image_base64 = data;
      }else {
        this.formData.base64 = [
          ...this.formData.base64,
          data,
        ];
      }
      // console.log(this.formData.base64)
    },
      
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getRepeaterData(data) {
      this.formData.times = data.items;
      this.formData.deleted_times = data.deleted_times;
      console.log(this.formData.times)
    },
    checkNulldesc() {
        if(!this.formData.descriptions.ar || this.formData.descriptions.ar == null || this.formData.descriptions.ar == '') {
          this.formData.descriptions.ar = this.formData.descriptions.en
        }
        if(!this.formData.descriptions.en || this.formData.descriptions.en == null || this.formData.descriptions.en == '') {
          this.formData.descriptions.en = this.formData.descriptions.ar
        }
    },
    searchPresenters(searchText) {
      this.getPresenters({
        keywords: searchText
      })
    }
  },

  computed: {
    ...mapGetters(['presenters', 'modalLoading']),
    restFields() {
      return [
        {name: 'Facebook', type: 'text', rules: '', model: 'facebook', cols: 4},
        {name: 'Twitter', type: 'text', rules: '', model: 'twitter', cols: 4},
        {name: 'Instagram', type: 'text', rules: '', model: 'instagram', cols: 4},
        {name: 'Whatsapp', type: 'text', rules: '', model: 'whatsapp', },
        {name: 'Youtube', type: 'text', rules: '', model: 'youtube', },
        {type: 'divider'},
        {
          name: 'Announcers', type: 'searchSelect', rules: 'required', model: 'announcers_id', value: 'id',
          multi: true , options: this.presenters, label: 'title', searchItems: this.searchPresenters, cols:12
        },
        // {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
      ]
    }
  }

}
</script>