var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('div',[(_vm.items.length > 0)?_c('b-form',{ref:"form",staticClass:"repeater-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},_vm._l((_vm.items),function(item,itemInd){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',[_c('label',{attrs:{"for":"day"}},[_vm._v(" "+_vm._s(_vm.$t('Day'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"day"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"day","options":_vm.days},model:{value:(item.day),callback:function ($$v) {_vm.$set(item, "day", $$v)},expression:"item.day"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('label',{attrs:{"for":"programTime"}},[_vm._v(" "+_vm._s(_vm.$t('From Time'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"programTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{attrs:{"placeholder":" -- : --"},model:{value:(item.from),callback:function ($$v) {_vm.$set(item, "from", $$v)},expression:"item.from"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('label',{attrs:{"for":"programend"}},[_vm._v(" "+_vm._s(_vm.$t('To Time'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"programend"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{attrs:{"placeholder":" -- : --"},model:{value:(item.to),callback:function ($$v) {_vm.$set(item, "to", $$v)},expression:"item.to"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"2"}},[_c('label',{attrs:{"for":"repeatTime"}},[_vm._v(" "+_vm._s(item.repeated ? _vm.$t('Repeat') : _vm.$t('Live'))+" ")]),_c('validation-provider',{attrs:{"rules":"","name":"repeatTime"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(item.repeated),callback:function ($$v) {_vm.$set(item, "repeated", $$v)},expression:"item.repeated"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(itemInd)}}},[(item.delete != true)?_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}):_c('span',[_vm._v(" "+_vm._s(_vm.$t('Deleted'))+" ")])],1)],1)],1)}),1):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon my-1",attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('add new')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }