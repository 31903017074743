import Axios from '../../libs/nabdIns';

export default {
  state: {
    // streams: [],
    videoStream: {},
    audioStream: {},
  },

  getters: {
    videoStream: (state) => state.videoStream,
    audioStreams: (state) => state.audioStreams,
  },

  mutations: {
    setVideoStream(state, payload) {
      state.videoStream = {...payload}
    },
    setAudioStream(state, payload) {
      state.audioStream = {...payload}
    },
    // removeStream(state, payload) {
    //   let index = state.streams.findIndex(el => el.id == payload);
    //   state.streams.splice(index, 1);
    // }
  },

  actions: {
    getAudioStream({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/audioStream/show/1`, {}, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setAudioStream', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getVideoStream({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/videoStream/show/1`, {}, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setVideoStream', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    createVideoStream({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/videoStream/save`, {
          id: payload.id,
          type : payload.type,
          url : payload.url,
          status : payload.status
        }, {
        })
        .then(res => {
          if(res.data.results != null) {
            // commit('setVideoStream', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    createAudioStream({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/audioStream/save`, {
          id: payload.id,
          type : payload.type,
          url : payload.url,
          status : payload.status
        }, {
        })
        .then(res => {
          if(res.data.results != null) {
            // commit('setVideoStream', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
  //   createSolution({commit}, payload) {
  //     console.log(payload)
  //     return new Promise ((resolve, reject) => {
  //       Axios.post(`/admin/solutions/save`, {
  //         id: payload.id,
  //         title: {
  //           ar: payload.titles.ar,
  //           en: payload.titles.ar,
  //         },
  //         description: {
  //           ar: payload.descriptions.ar,
  //           en: payload.descriptions.ar,
  //         },
  //         postion: {
  //           top: payload.top.toString(),
  //           left: payload.left.toString(),
  //         },
  //         category: payload.category,
  //         details_url: payload.details_url.toString(),
  //         video_url: payload.video_url,
  //         status: payload.status,
  //         images: payload.base64
  //       })
  //       .then(res => {
  //         if(res.data.results != null) {
  //           resolve(res);
  //         }else {
  //           reject(res)
  //         }
  //       }).catch(err => {
  //         reject(err)
  //       });
  //     })
  //   }, 
  //   deleteSolutions({commit}, payload) {
  //     return new Promise ((resolve, reject) => {
  //       Axios.post(`/admin/solutions/delete/${payload}`, {})
  //       .then(res => {
  //         if(res.data.results != null) {
  //           commit('removeSolution', res.data.results);
  //           resolve(res);
  //         }else {
  //           reject(res)
  //         }
  //       }).catch(err => {
  //         reject(err)
  //       });
  //     })
  //   }, 
  }
}