var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('div',[(_vm.items.length > 0)?_c('b-form',{ref:"form",staticClass:"repeater-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},_vm._l((_vm.items),function(item,itemInd){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',[_c('label',{attrs:{"for":"ProductID"}},[_vm._v(" "+_vm._s(_vm.$t('ProductID'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"ProductID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.products},on:{"search":_vm.searchProducts,"input":function($event){return _vm.setProductItems(itemInd, item)}},model:{value:(item.product),callback:function ($$v) {_vm.$set(item, "product", $$v)},expression:"item.product"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('label',{attrs:{"for":"item_id"}},[_vm._v(" "+_vm._s(_vm.$t('code'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"item_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"item_id","placeholder":"Item ID","state":errors.length > 0 ? false:null,"readonly":""},model:{value:(item.item_id),callback:function ($$v) {_vm.$set(item, "item_id", $$v)},expression:"item.item_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('label',{attrs:{"for":"quantity"}},[_vm._v(" "+_vm._s(_vm.$t('Quantity'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"quantity","placeholder":"Quantity","state":errors.length > 0 ? false:null,"type":"number"},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"1"}},[_c('label',{attrs:{"for":"value"}},[_vm._v(" "+_vm._s(_vm.$t('Unit Price'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"value","placeholder":"Value","state":errors.length > 0 ? false:null,"type":"number"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('label',{attrs:{"for":"net_value"}},[_vm._v(" "+_vm._s(_vm.$t('totalAfterDiscount'))+" ")]),_c('validation-provider',{attrs:{"name":"net_value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"net_value","state":errors.length > 0 ? false:null,"type":"number"},model:{value:(item.net_value),callback:function ($$v) {_vm.$set(item, "net_value", $$v)},expression:"item.net_value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('label',{attrs:{"for":"totalPaid"}},[_vm._v(" "+_vm._s(_vm.$t('totalPaid'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"totalPaid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"totalPaid","value":item.net_value ? item.net_value * item.quantity : item.value * item.quantity,"state":errors.length > 0 ? false:null,"readonly":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('label',{attrs:{"for":"expireDate"}},[_vm._v(" "+_vm._s(_vm.$t('expireDate'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"expireDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"expireDate","date-format-options":{ year: 'numeric', month: 'numeric', day: 'numeric' }},on:{"input":function($event){return _vm.getFormatedDate(item)}},model:{value:(item.expire_date),callback:function ($$v) {_vm.$set(item, "expire_date", $$v)},expression:"item.expire_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(itemInd)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}})],1)],1)],1)}),1):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon my-1",attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('add new')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }