<template>
  <div>
    <b-overlay
      rounded="sm"
      :show='loading || statsLoading'
      variant='transparent'
      blur='2px'
    >
      <!-- {{revenueObj}} -->
      <!-- {{costObj}} -->
      <b-row justify='center'>
        <b-col cols='12'>
          <EcommerceStatistics :obj='statisticsItems'/>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols='3'
        >
          <b-row>
            <b-col cols='12'>
              <EcommerceOrderChart :obj='orderData' :filter='graphFilters.orders' :timeFilter='timeFilter' :changeFilter='changeFilter'/>
            </b-col>
            <b-col cols='12'>
              <EcommerceProfitChart :obj='profitData' :filter='graphFilters.profit'/>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col cols='6'>
              <EcommerceProfitChart :obj='costData'/>
            </b-col>
            <b-col cols='6'>
              <EcommerceProfitChart :obj='totalData'/>
            </b-col>
          </b-row> -->
        </b-col>
        <b-col cols='9'>
          <!-- <EcommerceEarningsChart :obj='earningsData'/> -->
          <EcommerceRevenueReport :obj='revenueData' :filter='graphFilters.revenue'/>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <RecieptAnalysisTable :openModal='openModal' :closeModal='closeModal'  />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <OrderAnalysisTable  :openModal='openModal' :closeModal='closeModal'  />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import { 
  BCard, BCardText, BLink, BRow, BCol,
  BOverlay
} from 'bootstrap-vue'
import EcommerceStatistics from '@core/components/ecommerce/EcommerceStatistics.vue'
import EcommerceOrderChart from '@core/components/ecommerce/EcommerceOrderChart.vue'
import EcommerceProfitChart from '@core/components/ecommerce/EcommerceProfitChart.vue'
import EcommerceEarningsChart from '@core/components/ecommerce/EcommerceProfitChart.vue'
import EcommerceRevenueReport from '@core/components/ecommerce/EcommerceRevenueReport.vue'
import RecieptAnalysisTable from '@core/components/ecommerce/RecieptAnalysisTable.vue'
import OrderAnalysisTable from '@core/components/ecommerce/OrderAnalysisTable.vue'

import {mapActions, mapGetters} from 'vuex';


export default {
  components: {
    BRow, BCol,
    BCard,
    BCardText,
    BLink,
    BOverlay,
    EcommerceStatistics,
    EcommerceOrderChart,
    EcommerceProfitChart,
    EcommerceEarningsChart,
    EcommerceRevenueReport,
    RecieptAnalysisTable,
    OrderAnalysisTable,
  },
  data() {
    return {
      fromDate: '',
      toDate: '',
      now: new Date(Date.now()),
      sixMonth: [],
      orderObj: [],
      profitObj: [],
      deliveryObj: [],
      earningsObj: [],
      revenueObj: [],
      totalObj: [],
      discountObj: [],
      costObj: [],
      negativeCostObj: [],
      monthsText: [],
      orderMetaObj: [],
      fixedOrdersCount: '',
      graphFilters: {
        orders: {
          loading: false,
          filter:  1,
        },
        profit: {
          loading: false,
          filter:  1,
        },
        revenue: {
          loading: false,
          filter:  1,
        }
      }

    }
  },
  props: ['openModal', 'closeModal'],
  created() {
    this.$store.commit('setStatsLoading', true);
    let firstDayInMonth, lastDayInMonth;
    let nowMonth =  this.now.getMonth();
    let sixMon = [];

    this.fromDate = this.fomateDateShort(this.now, true);
    this.toDate = this.fomateDateShort(this.now, true);
    
    this.getRecieptStats({
      
    })
    this.getOrderStats({
      from_date: this.fromDate, 
      to_date: this.toDate
    })
    this.getSixMonth(nowMonth)
      .then(res => {
        let awaitData = setInterval(() => {
          if(this.renderMonths.length == 6) {
            clearInterval(awaitData)
            this.populateData();
          }else {

          }
        }, 200)
      })

    firstDayInMonth = new Date(this.now.getFullYear(), this.now.getMonth(), 1);
    lastDayInMonth = new Date(this.now.getFullYear(), this.now.getMonth() + 1, 0);
  },

  methods: {
    ...mapActions([ 'getProducts', 'getUsers', 'getRecieptStats', 'getOrderStats', 'getOrders']),
    getSixMonth(nowMonth) {
      return new Promise ((resolve, reject) => {
        let arrayIndex = 0;
        for( let i = -4 ; i < 2; i++ ) {
          let firstDay, lastDay;
  
          if(5 - nowMonth  >= 0) {
            firstDay = this.fomateDateShort(new Date(this.now.getFullYear()-1, this.now.getMonth() + (i-1), 1), true) ;
            lastDay = this.fomateDateShort(new Date(this.now.getFullYear()-1, this.now.getMonth() + i, 0), true);
            
            nowMonth ++;
          }else {
            firstDay = this.fomateDateShort(new Date(this.now.getFullYear(), this.now.getMonth() + (i-1), 1), true) ;
            lastDay = this.fomateDateShort(new Date(this.now.getFullYear(), this.now.getMonth() + i, 0), true);
          }
          this.monthsText.push((new Date(this.now.getFullYear()-1, this.now.getMonth() + (i-1), 1)).toDateString().split(' ')[1])
          this.getRecieptStats({
            from_date: firstDay, 
            to_date: lastDay
          }).then(outerRes => {
            this.getOrderStats({
              from_date: firstDay,
              to_date: lastDay
            }).then(orderStatsRes => {
              this.getOrders({
                from_date: firstDay,
                to_date: lastDay
              }).then(ordersRes => {
                this.sixMonth[i+4] = {
                  firstDay,
                  lastDay,
                  recieptStats: outerRes.data.results,
                  orderStats: orderStatsRes.data.results,
                  orderMeta: ordersRes.data.meta
                }
                // arrayIndex ++
                // console.log(arrayIndex, firstDay)
              })
            })
          })
        }
        
        
        resolve(true);
      })
    },
    getLastWeek(graph) {
      let weekDays = [];
      for(let i = 1; i < 9; i++) {
        weekDays.push(this.fomateDateShort(new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() -i), true))
      }
      console.log(weekDays)
    },
    getLastMonth(graph) {
      let firstDay, secondWeek, thirdWeek, forthWeek, lastDay;
      let lastMonth = [];
      firstDay = this.fomateDateShort(new Date(this.now.getFullYear(), this.now.getMonth() -1, 1), true) ;
      secondWeek = this.fomateDateShort(new Date(this.now.getFullYear(), this.now.getMonth() -1, 7), true);
      thirdWeek = this.fomateDateShort(new Date(this.now.getFullYear(), this.now.getMonth() -1, 14), true);
      forthWeek = this.fomateDateShort(new Date(this.now.getFullYear(), this.now.getMonth() -1, 21), true);
      lastDay = this.fomateDateShort(new Date(this.now.getFullYear(), this.now.getMonth(), 0), true);
      lastMonth.push(firstDay);
      lastMonth.push(secondWeek);
      lastMonth.push(thirdWeek);
      lastMonth.push(forthWeek);
      lastMonth.push(lastDay);
      
      switch(graph) {
        case 'orders': 
           this.orderObj = [];
          for(let i = 0; i < 4; i++) {
            this.getOrderStats({
              from_date: lastMonth[i],
              to_date: lastMonth[i+1]
            }).then(res => {
              this.orderObj.push(res.data.results.count);
            })
          }
          break;
        case 'profit': 
        this.profitObj = [];
          this.getLastMonth();for(let i = 0; i < 4; i++) {
            this.getOrderStats({
              from_date: lastMonth[i],
              to_date: lastMonth[i+1]
            }).then(res => {
              this.profitObj.push(res.data.results.totalProfit);
            })
          }
          break;
        case 3:
          this.getLastWeek();
          break;
      }
      this.orderObj = [];
      for(let i = 0; i < 4; i++) {
        this.getOrderStats({
          from_date: lastMonth[i],
          to_date: lastMonth[i+1]
        }).then(res => {
          this.orderObj.push(res.data.results.count);
        })
      }

    },
    populateData() {
      this.getUsers({
        role: 'users'
      });
      this.getOrders({})
      .then(res => {
        this.fixedOrdersCount = res.data.meta.total;
      })
      this.getProducts({})
        .then(res => {
          // console.log(this.productsMeta)
        })
      console.log(this.renderMonths[0])
      this.renderMonths.forEach(el => {
        // console.log(el)'
        this.orderMetaObj.push(el.orderMeta.total)
        this.orderObj.push(el.orderStats.count);
        this.profitObj.push(el.orderStats.totalProfit);
        this.deliveryObj.push(el.orderStats.shipping)
        this.discountObj.push(el.orderStats.discount)
        this.totalObj.push(el.orderStats.total)
        this.costObj.push(el.orderStats.totalCost)
        this.negativeCostObj.push(-el.orderStats.totalCost)
        // this.negativeCostObj.push(-5)
        this.revenueObj.push(el.orderStats.total_net)
        // this.revenueObj.push(10)
      })
      this.$store.commit('setStatsLoading', false);
      return;
    },
    filterData(graph) {
      switch(this.graphFilters[graph].id) {
        case 1: 
          this.getSixMonth();
          break;
        case 2: 
          this.getLastMonth();
          break;
        case 3:
          this.getLastWeek();
          break;
      }

      this.graphFilters[graph].loading == false;
    },
    changeFilter(graph, changedFilter) {
      this.graphFilters = {
        ...this.graphFilters,
        ...changedFilter,
      };

      this.filterData(graph);
    }
  },

  computed: {
    ...mapGetters([
      'statsLoading', 'loading', 
      'orderStats', 'recieptStats', 'productsMeta', "usersMeta", 'ordersMeta',

    ]),
    renderMonths() {
      return this.sixMonth;
    },
    statisticsItems() {
    return ( 
      [
        {
          id: 1,
          color: "light-primary",
          customClass: "mb-2 mb-xl-0",
          icon: "TrendingUpIcon",
          subtitle: this.$t("Sales"),
          title: this.fixedOrdersCount,
        },
        {
          id: 2,
          color: "light-info",
          customClass: "mb-2 mb-xl-0",
          icon: "UserIcon",
          subtitle: this.$t("Customers"),
          title: this.usersMeta.total,
        },
        {
          id: 3,
          color: "light-danger",
          customClass: "mb-2 mb-sm-0",
          icon: "BoxIcon",
          subtitle: this.$t("Products"),
          title:  this.productsMeta.total,
        },
      ]
    )},

    orderData() {
      return {
        series: [
          {
            data: this.orderMetaObj,
            name: this.$t('Orders')
          }
        ],
        total: this.ordersMeta.total,
        title: this.$t('Orders')
      };
    },
    profitData() {
       return {
        series: [
          {
            data: this.profitObj,
            name: this.$t('Profit')
          }
        ],
        title: this.$t('Profit'),
        variant: 'success'
      };
    },
    costData() {
       return {
        series: [
          {
            data: this.costObj,
            name: this.$t('Expense')
          }
        ],
        title: this.$t('Expense'),
        variant: 'danger'
      };
    },
    totalData() {
       return {
        series: [
          {
            data: this.totalObj,
            name: this.$t('Earning')
          }
        ],
        title: this.$t('Earning'),
        variant: 'primary'
      };
    },
    deliveryData() {
       return {
        series: [
          {
            data: this.deliveryObj,
            name: this.$t('Delivery')
          }
        ],
        title: this.$t('Delivery')
      };
    },
    revenueData() {
      return {
        series: [
          {
            data: this.revenueObj,
            name: this.$t('Earning')
          },
          {
            data: this.negativeCostObj,
            name: this.$t('Expense')
          }
        ],
        months: this.monthsText,
        title: this.$t('Revenue')
      };
    },
    timeFilter() {
      return  [
        {id: 1, text: this.$t('Last 6 months')},
        {id: 2, text: this.$t('this Month')},
        {id: 3, text: this.$t('this week')},
        
      ]
    } 
  }
}
</script>

<style>

</style>


<!--  this.getReciptStats({
      from_date: this.fromDate,
      to_date: this.toDate,
    }).then(res => {
      console.log(this.recieptSats)

    });
    this.getOrdersStats({

    })