<template>
  <div class="" v-if='!formLoading'>
    <ResponsiveFieldsForm 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
    />
    
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';

import {
  BRow, BCol
} from 'bootstrap-vue';

import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    ResponsiveFieldsForm,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow, BCol
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        // color: '#FFFFFF',
        status: true,
      }
    }
  },

  props: ['editObj', 'closeModal',],
  mounted() {
    if(this.editObj != null) {
      this.formLoading = true
      this.getPage(this.editObj.id)
        .then(res => {
        this.formObj = res.data.results;
        this.formData.id = this.editObj.id;
        setTimeout(() => {
          this.formLoading = false;
        }, 100)
      })
    }
  },
  methods: {
    ...mapActions(['getPages', 'getPage', 'createPage']),
    formSubmit()  {
      this.$refs.restForm.submit()
        .then(res => {
          if(res == true) {
            if(res == true) {
              this.$store.commit('setModalLoading', true);
              this.$refs.restForm.submit();
              // console.log(this.formData)
              setTimeout(() => {
                this.createPage(this.formData)
                  .then(res => {
                    this.closeModal();
                    this.getPages({});
                    this.$store.commit('setModalLoading', false);
                  })
              }, 100)
            }
          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      console.log(data)
      this.formData.base64 = [];
      if(this.editObj && this.editObj.image) {
        this.formData.base64 = [
          {
            ...this.editObj.image,
            delete: true
          }
        ]
      }
        
      this.formData.base64 = [
        ...this.formData.base64,
        data,
      ];
    },
      
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if(this.formData.descriptions.ar == null || this.formData.descriptions.en == null) {
        if(this.formData.descriptions.ar == null) {
          this.formData.descriptions.ar = ''
        }
        if(this.formData.descriptions.en == null) {
          this.formData.descriptions.en = ''
        }
      }
    }
  },

  computed: {
    ...mapGetters(['product', 'modalLoading']),
    restFields() {
      return [
        {name: 'titles', type: 'text', rules: 'required', model: 'titles', },
        {name: 'path', type: 'text', rules: 'required', model: 'path' },
        {name: 'ord', type: 'number', rules: 'required', model: 'ord' },
        {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
      ]
    }
  }

}
</script>