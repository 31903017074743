<template>
  <b-card
    no-body
    class="invoice-preview-card"
  >
    <!-- Header -->
    <b-card-body class="invoice-padding pb-0">

      <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

        <!-- Header: Left Content -->
        <div>
          <!-- <div class="logo-wrapper">
            <h3 class="text-primary invoice-logo">
              <img  src='@/assets/images/logo/NoPath.webp' width="5%" height="5%" />
              <span class="mx-1">Strata</span>
            </h3>
          </div> -->
          <h4 class="invoice-title">
            {{$t('ID')}}
            <span class="invoice-number">#{{ editObj.id }}</span>
          </h4>
          <p class="card-text mb-25 mt-2">
            <!-- {{$t('Area')}} : {{editObj.area ? editObj.area.name : ''}} -->
            {{$t('Name')}} : {{editObj.name}}
          <p class="card-text mb-25">
            {{$t('phone')}} : {{editObj.phone}},
          </p>
          <p class="card-text mb-25">
            {{$t('email')}} : {{editObj.email}}
          </p>
        </div>

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-title">
            {{$t('ID')}}
            <span class="invoice-number">#{{ editObj.id }}</span>
          </h4>
          <div class="invoice-date-wrapper">
            <p class="invoice-date-title">
              {{$t('Date')}}:
            </p>
            <p class="invoice-date">             
              {{ this.$i18n.locale == 'ar' ? this.fomateDateShort2(editObj) : this.formateDate(editObj) }}
            </p>
          </div>
        </div>
      </div>
    </b-card-body>
    <!-- Spacer -->
    <hr class="invoice-spacing">
    <!-- Invoice Description: Table -->
    <b-table-lite
      responsive
      :items="orders"
      :fields="fields"
    >
    </b-table-lite>
    <hr class="invoice-spacing">
    <!-- Invoice Description: Total -->
    <b-card-body class="invoice-padding pb-0">
      <b-row>

        <!-- Col: Sales Persion -->
        <b-col
          cols="12" 
          md='7'
          class="mt-md-0 mt-3"
          order="2"
          order-md="1"
        >
        </b-col>

        <!-- Col: Total -->
        <b-col
          cols="12"
          md='5'
          class="mt-md-6 d-flex justify-content-end"
          order="1"
          order-md="2"
        >
          <div class="invoice-total-wrapper">
            <hr class="my-50">
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{$t('Total Orders')}}:
                <span class=" mx-1 invoice-total-amount">
                  {{totalOrders}}
                </span>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>


<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
  BFormSelect, BForm, BFormGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { title } from '@/@core/utils/filter'
import {mapActions, mapGetters} from 'vuex'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormSelect, BForm, BFormGroup,
    vSelect
  },
  data() {
    return {
       fields: [
        {
          key: 'id',
          label: this.$t('ID')
        },
        {
          key: 'created_at',
          label: this.$t('date'),
          formatter: (value, key, item) => this.fomateDateShort2(item),
        },
        
        {
          key: 'status',
          label:  this.$t('Status'),
          formatter: (value, key, item) => this.formateStatus(item),
          sortable: true
        },
        {
          key: 'delivery',
          label: this.$t('delivery'),
          formatter: (value, key, item) => this.formateDayTime(item),
        },
        {
          key: 'total',
          label: this.$t('total'),
          sortable: true
        },

      ],
      payOptions: [
        {id: 1, text: this.$t('paid'), value: true},
        {id: 2, text: this.$t('not paid'), value: false},
      ],  
      statusData: {
        payment: false,
        delivery_id: 0,
        driver_id: null,
        seller_id: null,
      },
      totalOrders: 0,
    }
  },
  props: ['editObj', 'closeModal'],
  mounted() {
    console.log(this.editObj)
    this.getOrders({
      keywords: this.editObj.name,
    }).then(res => {
      this.totalOrders = res.data.meta.total
    })
    this.getOrdersStatus({})
  },
  methods: {
    ...mapActions(['getSettings', 'getOrdersStatus',  'updateOrder', 'getOrders', 'getFilteredUsers']),
    formSubmit() {
      this.updateOrder({
        id: this.editObj.id,
        status_id: this.statusData.delivery_id,
        paid: this.statusData.payment,
        driver_id: this.statusData.driver_id,
        seller_id: this.statusData.seller_id
      }).then(res => {
        console.log(res);
        this.closeModal()
        this.getOrders({});
      })
    },
    searchSellers(searchText) {
      this.getFilteredUsers({
        keywords: searchText,
        perm: 'users.seller'
      })
    },
    searchDrivers(searchText) {
      this.getFilteredUsers({
        keywords: searchText,
        perm: 'users.driver'
      })
    }
  },

  computed: {
    ...mapGetters(['settings', 'ordersStatus', 'filteredUsers', 'filteredSellers', 'orders', 'ordersMeta'])
  }
}
</script>




<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  
  

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
