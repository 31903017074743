import Axios from '../../libs/nabdIns';

export default {
  state: {
    presenters: [],
    presenter: {},
  },

  getters: {
    presenters: (state) => state.presenters,
    presenter: (state) => state.presenter,
  },

  mutations: {
    setPresenters(state, payload) {
      state.presenters = [...payload]
    },
    setPresenter(state, payload) {
      state.presenter = {...payload}
    },
    removePresenter(state, payload) {
      let index = state.presenters.findIndex(el => el.id == payload);
      state.presenters.splice(index, 1);
    }
  },

  actions: {
    getPresenters({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/presenters`, {
          keywords: payload.keywords,
          locale: payload.locale,
          page: payload.page,
          stauts: payload.stauts,
        }, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setPresenters', res.data.results.data);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getPresenter({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/presenters/show/${payload}`, {}, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setPresenter', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    createPresenter({commit}, payload) {
      console.log(payload)
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/presenters/save`, {
          id: payload.id,
          titles: payload.titles,
          descriptions: payload.descriptions,
          contact: {
            facebook: payload.facebook,
            twitter: payload.twitter,
            instagram: payload.instagram,
            whatsapp: payload.whatsapp,
            youtube: payload.youtube,
          },
          cover_image_base64: payload.cover_image_base64,
          image_base64: payload.image_base64,
          
        })
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deletePresenters({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/presenters/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removePresenter', payload);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
  }
}