import Axios from '../../libs/strataIns';

export default {
  state: {
    testmonials: [],
    testmonial: {},
  },

  getters: {
    testmonials: (state) => state.testmonials,
    testmonial: (state) => state.testmonial,
  },

  mutations: {
    setTestmonials(state, payload) {
      state.testmonials = [...payload]
    },
    setTestmonial(state, payload) {
      state.testmonial = {...payload}
    },
    removeTestmonial(state, payload) {
      let index = state.testmonials.findIndex(el => el.id == payload);
      state.testmonials.splice(index, 1);
    }
  },

  actions: {
    getTestmonials({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/testimonials`, {
          keywords: payload.keywords,
          locale: payload.locale,
          page: payload.page,
          stauts: payload.stauts,
        }, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setTestmonials', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getTestmonial({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/testimonials/show/${payload}`, {}, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setTestmonial', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    createTestmonial({commit}, payload) {
      console.log(payload)
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/testimonials/save`, {
          id: payload.id,
          titles: {
            ar: payload.titles.ar,
            en: payload.titles.ar,
          },
          descriptions: {
            ar: payload.descriptions.ar,
            en: payload.descriptions.ar,
          },
          info: payload.info,
          rating: payload.rating,
          status: payload.status,
          image_base64: payload.base64 ? {...payload.base64[0]} : undefined,
          icon_base64: payload.base64 ? {...payload.base64[0]} : undefined,
        })
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteTestmonials({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/testimonials/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeTestmonial', payload);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
  }
}