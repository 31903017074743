<template>
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent="submit">
      <b-row>
        <!-- name -->
        <b-col
          v-for="(field, fIndex) in formFields"
          :key="fIndex"
          :cols="field.cols == 'auto' ? '' : field.cols || 6"
          class="align-self-center"
        >
          <div
            v-if="
              !field.vifAttribute ||
              formData[field.vifAttribute] != field.vifValue
            "
          >
            <b-form-group :label="$t(field.name)" :label-for="field.name">
              <validation-provider
                #default="{ errors }"
                :rules="`${field.rules}`"
                :name="field.name"
              >
                <v-select
                  v-if="field.type == 'searchSelect'"
                  :id="field.name"
                  :options="field.options"
                  :label="field.label"
                  :reduce="(item) => item.id"
                  v-model="formData[field.model]"
                  :disabled="field.disabled"
                  @search="field.searchItems"
                ></v-select>

                <v-select
                  v-else-if="field.type == 'searchSelectItem'"
                  :id="field.name"
                  :options="field.options"
                  :label="field.label"
                  :reduce="(item) => item"
                  v-model="formData[field.model]"
                  :disabled="field.disabled"
                  @search="field.searchItems"
                ></v-select>

                <b-form-select
                  v-else-if="field.type == 'select'"
                  :id="field.name"
                  :options="field.options"
                  :text-field="field.label"
                  value-field="value"
                  v-model="formData[field.model]"
                  :disabled="field.disabled"
                ></b-form-select>

                <b-form-checkbox
                  v-else-if="field.type == 'checkbox'"
                  v-model="formData[field.model]"
                  :id="field.name"
                  switch
                  inline
                >
                  <!-- {{field.name}} -->
                </b-form-checkbox>
                <div v-else-if="field.type == 'divider'" class="w-100"></div>
                <b-form-input
                  v-else
                  :type="field.type"
                  :id="field.name"
                  :placeholder="field.placeholder"
                  v-model="formData[field.model]"
                  :state="errors.length > 0 ? false : null"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import vSelect from "vue-select";

import { mapActions, mapGetters } from "vuex";

// optionally import default styles

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormSelect,
  },
  data() {
    return {
      formData: {},
      nameLang: "ar",
      descLang: "ar",
      loading: false,
      required,
      password,
      email,
      confirmed,
      between,
    };
  },
  watch: {},
  props: ["defaultItem", "getRestData", "editObj", "formFields"],
  mounted() {
    if (this.editObj != null && this.editObj != "null") {
      this.loading = true;
      this.formData = this.editObj;
      if (this.editObj.status && this.editObj.status == 1) {
        this.formData.status = true;
      }
    } else if (this.editObj == undefined) {
      this.formData = {};
      this.formData.status = true;
    } else {
      this.formData = this.editObj;
      this.$forceUpdate();
    }
    this.loading = false;
  },
  methods: {
    submit() {
      return this.$refs.simpleRules.validate().then((res) => {
        if (res == true) {
          this.getRestData(this.formData);
          return true;
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    exampleFields() {
      return [
        {
          name: "searchSelect",
          type: "searchSelect",
          rules: "required",
          options: ["items"],
          label: "label",
          model: "selectAttr",
          disabled: false,
          searchItems: (searchText) => console.log("searching", searchText),
        },
        {
          name: "select",
          type: "select",
          rules: "required",
          options: ["items"],
          label: "label",
          model: "selectAttr",
        },
        {
          name: "checkbox",
          type: "checkbox",
          rules: "required",
          model: "selectAttr",
        },
        {
          name: "color",
          type: "color",
          placeholder: "placeholder",
          rules: "required",
          model: "selectAttr",
        },
        {
          name: "phone",
          type: "tel",
          placeholder: "placeholder",
          rules: "required",
          model: "selectAttr",
          vifAttribute: "field.vifAttribute",
          vifValue: "field.vifValue",
        },
      ];
    },
  },
  directives: {
    Ripple,
  },
};
</script>
