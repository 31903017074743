<template>
  <b-card
      no-body
    >
    <b-card-header>
      <b-card-title>{{$t('Orders')}} ( {{ordersMeta.total}} )</b-card-title>
    </b-card-header>
    <b-row class="mx-2">
        <b-col cols='4'>
           <b-form-group
            :label="$t('from Date')"
            label-for="fromdateorder"
           >
            <b-form-datepicker
              v-model="fromDate"
              :date-disabled-fn="dateDisabled"
              :locale="locale == 'en' ? 'en' : 'ar-EG'"
              id='fromdateorder'
              @input='filterItems'
            />
           </b-form-group>
          
        </b-col>
        <b-col cols='4'>
           <b-form-group
            :label="$t('To Date')"
            label-for="todateorder"
           >
            <b-form-datepicker
              v-model="toDate"
              :date-disabled-fn="dateDisabled"
              :locale="locale == 'en' ? 'en' : 'ar-EG'"
              id='todateorder'
              @input='filterItems'
            />
           </b-form-group>
          
        </b-col>
      </b-row>
    <b-table
      responsive
      :items="orders"
      :fields="fields"
      class="mb-0"
    >
      <template #cell(status)="data">
       
        <b-row >
          <b-col >
              <b-avatar
                :id="`invoice-row-${data.item.id}`"
                size="32"
                :variant="`light-${resolveOrderStatusVariantAndIcon(data.item.status_id).variant}`"
              >
                <feather-icon
                  :icon="resolveOrderStatusVariantAndIcon(data.item.status_id).icon"
                />
              </b-avatar>
              <b-tooltip
                :target="`invoice-row-${data.item.id}`"
                placement="top"
              >
                <p class="mb-0">
                  Status: {{ ordersStatus.find(el => data.item.status_id == el.id).title }}
                </p>
                <p class="mb-0">
                  Payment Status: {{ data.item.payments_status && data.item.payments_status.paid == true ? $t('paid') : $t('not paid')}}
                </p>
                <p class="mb-0">
                  Total: {{ data.item.total_net }}
                </p>
              </b-tooltip>
          </b-col>
          <!-- <b-col >
            <b-form-select
              :options='ordersStatus'
              text-field="title"
              value-field="id"
              v-model='data.item.status_id'
              @change='saveOrder(data.item)'
            ></b-form-select>
          </b-col> -->
        </b-row>
      
        
      </template>
      <template #cell(address)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.avatar"
              :text="avatarText(data.item.address.name)"
              :variant="`light-${resolveOrderStatusVariantAndIcon(data.item.status_id).variant}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.address.name }}
          </span>
          <small class="text-muted">{{ data.item.address.phone }}</small>
        </b-media>
      </template>

      <template #cell(payment)="data">
        <template v-if="data.item.payments_status && data.item.payments_status.paid == true">
          <b-badge
            pill
            variant="light-success"
          >
            {{$t('paid')}}
          </b-badge>
        </template>
        <template v-else> 
          <b-badge
              pill
              variant="light-danger"
            >
              {{$t('not paid')}}
          </b-badge>
          <!-- {{ data.item.total_net }} -->
        </template> 
      </template>

      <template #cell(driver_id)="data">
        <!-- <v-select
          :options='filteredUsers'
          label="name"
          :reduce='driver => driver.id'
          v-model='data.item.driver_id'
          @input='saveOrder(data.item)'
          @search='searchDrivers'
        ></v-select> -->
        {{data.item.driver ? data.item.driver.name : ' - '}}
      </template>
      
      <template #cell(seller_id)="data">
        <!-- <v-select
          :options='filteredSellers'
          label="name"
          :reduce='seller => seller.id'
          v-model='data.item.seller_id'
          @input='saveOrder(data.item)'
          @search='searchSellers'
        ></v-select> -->
        {{data.item.seller ? data.item.seller.name : ' - '}}
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
      <template #cell(actions)="row">
          <b-button 
            size="sm" 
            @click="sendMessage(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="MessageCircleIcon"
              size="15"
            />
          </b-button>
          <!-- <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button> -->
          <!-- <b-button 
            size="sm" 
            @click="editItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="Edit2Icon"
              size="15"
            />
          </b-button> -->
          <b-button 
            size="sm" 
            @click="downloadInvoice(row)"
            variant="flat-secondary"  
            class="btn-icon"
          >
            <feather-icon
              icon="DownloadIcon"
              size="15"
            />
          </b-button>
          <!-- <b-button 
            size="sm" 
            @click="deleteItem(row)"
            variant="flat-danger"  
            class="btn-icon"
          >
            <feather-icon
              icon="Trash2Icon"
              size="15"
            />
          </b-button> -->
        </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @page-click='filterItems'
      />
    </b-card-footer>
  </b-card>
</template>

<script>
import { BTable, BButton, BRow, BCol,BFormGroup, BFormInput, BFormSelect,  
  BPagination, BCard, BCardHeader, BCardTitle ,BCardFooter,
  BTooltip,  BAvatar, BBadge, BMedia, BFormDatepicker,
} from 'bootstrap-vue';
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { mapActions, mapGetters } from 'vuex';
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BRow, BCol,BFormGroup, BFormInput, BFormSelect,
    BPagination,
    BCard, BCardHeader,BCardTitle,BCardFooter,
    BTooltip,  BAvatar, BBadge, BMedia, BFormDatepicker,
    vSelect
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: this.$t('ID'),
          sortable: true
        },
        {
          key: 'address',
          label: this.$t('client'),
          sortKey: 'address.name',
          sortable: true,
        },
        {
          key: 'status',
          label:  this.$t('Status'),
          formatter: (value, key, item) => this.formateStatus(item),
          sortable: true,
        },
        {
          key: 'driver_id',
          label: this.$t('driver'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('date'),
          formatter: (value, key, item) => this.fomateDateShort2(item),
          sortable: true,
        },
        {
          key: 'total',
          label: this.$t('total'),
          sortable: true,
        },,
        {
          key: 'payment',
          label: this.$t('payment'),
        },,
        {
          key: 'seller_id',
          label:  this.$t('seller'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('actions')
        },
      ],
      searchText: '',
      selected: '',
      currentPage : 1,
      perPage: 10,
      rows: 0,
      fromDate: '',
      toDate: '',
    }
  },
  props: ['openModal', 'closeModal', 'here'],
  created() {
    this.getOrdersStatus({});
    this.getFilteredUsers({
      perm: 'users.driver'
    })
    this.getFilteredUsers({
      perm: 'users.seller'
    })
    this.getOrders({})
      .then(res => {
        this.rows =res.data.meta.total
      }).catch(err => console.error(err))
  },

  methods: {
    ...mapActions(['getOrders', 'getFilteredUsers', 'updateOrder']),
    filterItems() {
      setTimeout(() => {

        this.getOrders({
          page: this.currentPage,
          keywords: this.searchText,
          status_id: this.selected,
          from_date: this.fromDate,
          to_date: this.toDate,
        }).then(res => {
          console.log(res)
        }).catch(err => {

      })
        }, 100)  
    },
    showItem(row) {
      this.openModal(row, 'ShowHomeOrder')
      // console.log(row)
    },
    
    editItem(row) {
      this.openModal(row, 'Edit')
    },

    downloadInvoice(row) {
      this.openModal(row, 'ShowHomeOrder');
      let interval = setInterval(() => {
        if(this.loading || this.modalLoading) {

        }else {
          window.print();
          clearInterval(interval)
        }
      }, 100);
    },
    
    sendMessage(row) {
      // console.log(row);
      this.openWhatsappConve(row);
    },
    saveOrder(item) {
      this.updateOrder({
        id: item.id,
        status_id: item.status_id,
        driver_id: item.driver_id,
        seller_id: item.seller_id
      }).then(res => {
        this.getOrders({});
        // this.getFilteredUsers({
        //   perm: 'users.driver'
        // })
        // this.getFilteredUsers({
        //   perm: 'users.seller'
        // })
      })
    },

    searchSellers(searchText) {
      this.getFilteredUsers({
        keywords: searchText,
        perm: 'users.seller'
      })
    },
    searchDrivers(searchText) {
      this.getFilteredUsers({
        keywords: searchText,
        perm: 'users.driver'
      })
    },
    dateDisabled() {

    }
  },

  computed: {
    ...mapGetters(['orders', 'ordersStatus', 'loading', 'filteredUsers', 'filteredSellers', 'modalLoading', 'ordersMeta']),
  },
  setup(props) {
    return {
       avatarText,
    }
  }
}
</script>