<template>
  <div class="">
    <div class="">
    </div>
    <div v-if='formObj != null && !formLoading' >
      <!-- {{userProfile}} -->
      <!-- <BaseForm 
        :editObj='formObj' 
        :getBaseData='getBaseData' 
        ref='baseForm' 
        :noDiscription="true"
      /> -->
      <!-- User Info: Input Fields -->
      <hr />
      <ResponsiveFormFields 
        v-if='formObj != null && !formLoading' 
        :defaultItem="defaultItem" 
        :editObj='formObj' 
        :getRestData='getRestData' 
        :formFields="restFields" 
        ref='restForm' 
      />     
      <!-- PERMISSION TABLE -->
    </div>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { mapGetters, mapActions } from 'vuex'
import BaseForm from './BaseForm.vue';
import ResponsiveFormFields from './ResponsiveFormFields.vue';
import PermissionsForm from './PermissionsForm.vue';

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BaseForm,
    ResponsiveFormFields,
    PermissionsForm,
  },
  data() {
    return {
      formLoading: false,
      formData: {},
      formObj: null,
      defaultItem: {},
      
    }
  },
  props: ['editObj', 'closeModal'],
  mounted() {
    this.formLoading = true;
    console.log('this.editObj', this.editObj);
    this.getRoles({})
    if(this.editObj != null) {
      this.getUser(this.editObj.id)
        .then(res => {
          this.formObj = {...res.data.results};
          this.formData.id = this.editObj.id
          this.formLoading = false;
        })
      console.log('user here', this.filteredUser, this.user)
    }else {
      this.formData = {}
      this.formObj = {
        status: true,
      };
      this.formLoading = false;
    }
  },
  methods: {
    ...mapActions(['getRoles', 'createUser', 'getFilteredUsers', 'getUser']),
    formSubmit() {
        this.$refs.restForm.submit()
          .then(res => {
            if(res == true) {
              this.$store.commit('setModalLoading', true);
              // this.$refs.baseForm.submit();
              // this.$refs.restForm.submit();
              setTimeout(() => {
                this.createUser(this.formData)
                  .then(res => {
                    this.closeModal();
                    this.getFilteredUsers({
                      // permissions: [this.$route.meta.userRole]
                    });
                    this.$store.commit('setModalLoading', false);
                  })
              }, 100)
            }
          })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      // this.formData.descriptions = data.descriptions;
    },
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getPermissionsData(data) {
      this.formData.permissions = data;
    },
    searchRoles(searchText) {
      this.getRoles({
        keywords: searchText,
      })
    }
  },
  computed: {
    ...mapGetters(['userProfile', 'filteredUser', 'permissions', 'roles']),
    titleRoles() {
      return this.roles.map(el => {
        return {
          ...el,
          title: el.titles[this.locale]
        }
      })
    },
    restFields() {
      return [
        {
          name: this.$t('name'), 
          type: 'text', 
          rules: '', 
          model: 'name',
        },
        {
          name: this.$t('email'), 
          type: 'email', 
          rules: 'email', 
          model: 'email',
        },
        {
          name: this.$t('role'), 
          type: 'searchSelect', 
          rules: 'required', 
          model: 'role_id',
          options: this.titleRoles,
          label: 'title',
          searchItems: this.searchRoles,
        },
        {
          name: this.$t('phone'), 
          type: 'text', 
          rules: 'required', 
          model: 'phone',
        },
        {
          name: this.$t('password'), 
          type: 'password', 
          rules: '', 
          model: 'password',
        },
        {
          name: this.$t('status'), 
          type: 'checkbox', 
          rules: 'required', 
          model: 'status',
        },
      ]
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
