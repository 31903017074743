<template>
  <validation-observer ref="simpleRulesouter" class="" >
   <b-form @submit.prevent="formSubmit">
      <b-row class="my-1">
        <b-col>
          <validation-provider
            #default="{ errors }"
            rules="required"
            name='Supplier ID'
          >
            <label for='ProductID'>
              {{$t('Supplier ID')}}
            </label> 
            <v-select
              v-model="formData.supplier_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :reduce="supplier => supplier.id"
              :options="suppliers"
              @search='searchSuppliers'
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>  
      </b-col>
      <b-col>
        <validation-provider
          #default="{ errors }"
          name='Status'
        >
          <label for='Status'>
            {{$t('Status')}}
          </label> 
          <v-select
            v-model="formData.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :reduce="status => status.status"
            :options="recieptStatus"
          />
          <small class="text-danger">{{ errors[0] }}</small> 
        </validation-provider>  
    </b-col>
    </b-row>
    <hr />
     <b-row class="my-1" v-if='!modalLoading && !formLoading'>
       <b-col>
         <h4 class="mb-1">
          {{$t('Items')}}
        </h4> 
        <RecieptRepeater :editObj='formObj' ref='recipetRepeater' :getRepeaterData='getRepeaterData' />
       </b-col>
     </b-row>
   </b-form>
  </validation-observer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseForm from './BaseForm';
import ImgForm from './ImgForm';
import RestFormCat from './RestFormCat';
import vSelect from 'vue-select'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
  BFormSelect, BForm, BFormGroup,
} from 'bootstrap-vue'
import RecieptRepeater from './RecieptRepeater';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BaseForm,
    ImgForm,
    RecieptRepeater,
    RestFormCat,
    BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
    BFormSelect, BForm, BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      formData: {
        supplier_id: '',
        on_date: new Date(),
        status: null
      },
      formObj: null,
      formLoading: false,
    }
  },
  props: ['editObj', 'closeModal'],
  mounted() {
    this.formLoading = true;
    this.$store.commit('setModalLoading', true);
    if(this.editObj != null) {
      this.formObj = this.editObj;
      // console.log(this.editObj, this.formObj, 'edit');
      this.formData.id = this.editObj.id;
      
    }
    this.getSuppliers({})
      .then(res => {
        if(this.editObj != null) {
          this.formData.supplier_id = this.editObj.supplier.id;
          this.formData.status = this.editObj.status;
        }
        this.getProducts({})
        .then(res => {
          this.$store.commit('setModalLoading', false);
          this.formLoading = false;

        })
      });
    
  },
  methods: {
    ...mapActions(['getReciepts', 'getSuppliers', 'getCategory', 'createReciept', 'getProducts']),
    formSubmit() {
      this.$refs.simpleRulesouter.validate()
        .then(res => {
          if(res == true) {
            this.$refs.recipetRepeater.submit()
              .then(res => {
                if(res == true) {
      
                  this.$store.commit('setModalLoading', true);
                  // this.$refs.baseForm.submit()
                  // this.$refs.imgForm.submit()
                  this.getDateNow()
                  this.$refs.recipetRepeater.submit()
                  console.log(this.formData)  
                  this.createReciept(this.formData)
                    .then(res => {
                      console.log(res)
                      this.getReciepts({});
                      this.closeModal();
                      this.$store.commit('setModalLoading', false);
                    })
                    .catch(err => console.log(err))
                }else {
                  return false;
                }
              });
          }
        })
      
    },
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      this.formData.base64 = data;
    },
    getRepeaterData(data) {
      this.formData = {
        ...this.formData,
        items: [...data],
      }
    },
    searchSuppliers(searchText) {
      this.getSuppliers({
        keywords: searchText
      }).then(res => {
        console.log(res)
      })
    },
    getDateNow() {
      let dt = new Date(Date.now());
      dt = `${
        dt.getFullYear().toString().padStart(4, '0')}-${
        (dt.getMonth()+1).toString().padStart(2, '0')}-${
          dt.getDate().toString().padStart(2, '0')} ${
          dt.getHours().toString().padStart(2, '0')}:${
            dt.getMinutes().toString().padStart(2, '0')}:${
              dt.getSeconds().toString().padStart(2, '0')
        }`;

      // console.log(dt)
      this.formData.on_date = dt
      // console.log(this.formData.on_date)
    }
  },
  computed: {
    ...mapGetters(['suppliers', 'loading', 'modalLoading']),

  }
}
</script>