<template>

  <div class="" v-if='!loading && !formLoading'>
    <BaseForm  :editObj='formObj' :getBaseData='getBaseData' ref='baseForm'/>
    <hr />
    
    <b-form @submit.prevent='submit'>
      <b-row>
        <b-col>
          <b-form-group
            :label="$t('true value')"
            label-for="truevalue"
          >
            <b-form-select
              id='truevalue'
              :options="trueValue"
              text-field="text"
              value-field="value"
              v-model='statusData.status_value'
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label="$t('ord')"
            label-for="ord"
          >
            <b-form-input
              id='ord'
              type='number'
              v-model='statusData.ord'
            ></b-form-input>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label="$t('status')"
            label-for="status"
          >
            <b-form-checkbox
              id='status'
              switch
              v-model='statusData.status'
            ></b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label="$t('Default status')"
            label-for="default"
          >
            <b-form-checkbox
              id='default'
              switch
              v-model='statusData.default_start'
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

  </div>
</template>

<script>
import BaseForm from './BaseForm';
import {
  BFormGroup, BForm, BFormCheckbox, BFormInput, BFormSelect, 
  BRow, BCol
} from 'bootstrap-vue'
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {
    BaseForm,
    BFormGroup, BForm, BFormCheckbox, BFormInput, BFormSelect,
    BRow, BCol,
  },

  data() {
    return {
      statusData: {
        ord: 0,
        status: true,
        default_start: false,
        status_value: -1,
      },
      formData: {
        titles: ''
      },
      trueValue: [
        {id: 1, value: -1, text: 'Canceled'},
        {id: 2, value: 1, text: 'Confirmed'},
        {id: 3, value: 2, text: 'In shipping proccess'},
        {id: 4, value: 3, text: 'Shipped'},
        {id: 5, value: 4, text: 'On the way'},
        {id: 6, value: 5, text: 'Deliveried'} ,
      ],
      formObj: null,
      formLoading: false,
    }
  },

  props: ['editObj', 'closeModal'],
  mounted() {
    if(this.editObj != null) {
      this.formLoading = true;
      this.getOrderStatus({
        id: this.editObj.id
      }).then(res => {
        this.formObj = res.data.results;
        this.statusData = {...res.data.results}
        this.formLoading = false;
      })
    }
    console.log(this.editObj);
  },
  methods: {
    ...mapActions(['createOrderStatus', 'getOrdersStatus', 'getOrderStatus']),
    formSubmit() {
      this.$refs.baseForm.submit()
        .then(res => {
          this.$refs.baseForm.submit();
          if(!this.formData.titles.ar || !this.formData.titles.en) {
            if(!this.formData.titles.ar) {
              this.formData.titles.ar = this.formData.titles.en;
            }else {
              this.formData.titles.en = this.formData.titles.ar;
            }
          }
          this.formData = {
            ...this.formData,
            ...this.statusData,
          }
          setTimeout(() => {
            this.createOrderStatus(this.formData)
            .then(res => {
              console.log(res)
              this.getOrdersStatus({});
              this.closeModal();
            })
          }, 100)

        })
      console.log('submitted')
    },
    getBaseData(data) {
      this.formData.titles = data.titles;
    },
  },

  computed: {
    ...mapGetters(['orderStatus'])
  },

}
</script>
<!-- 


-->