<template>
  <b-card
    no-body
    class="invoice-preview-card"
  >
    <!-- Header -->
    <b-card-body class="invoice-padding pb-0">

      <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

        <!-- Header: Left Content -->
        <div>
          <div class="logo-wrapper">
            <h3 class="text-primary invoice-logo">
              <img  src='@/assets/images/logo/NoPath.png' width="5%" height="5%" />
              <span class="mx-1">Strata</span>
            </h3>
          </div>
          <p class="card-text mb-25 mt-2">
            <!-- {{$t('Area')}} : {{editObj.area ? editObj.area.name : ''}} -->
            {{$t('Area')}} : {{editObj.address.area_no}}
          <p class="card-text mb-25">
            {{$t('Street')}} : {{editObj.address.street}},
          </p>
          <p class="card-text mb-25">
            {{$t('Home Number')}} : {{editObj.address.home_no}}
          </p>
          <h4 class="mt-1">{{editObj.address.name}}</h4>
          <p class="card-text mb-0">
            {{editObj.address.phone}}
          </p>
        </div>

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-title">
            {{$t('ID')}}
            <span class="invoice-number">#{{ editObj.id }}</span>
          </h4>
          <div class="invoice-date-wrapper">
            <p class="invoice-date-title">
              {{$t('Date')}}:
            </p>
            <p class="invoice-date">             
              {{ this.$i18n.locale == 'ar' ? this.fomateDateShort2(editObj) : this.formateDate(editObj) }}
            </p>
          </div>
          <div class="invoice-date-wrapper">
            <p class="invoice-date-title">
              {{$t('Delivery')}}:
            </p>
            <p class="invoice-date">     
              <!-- {{editObj.recurring}}         -->
              {{ this.formateDayTime(editObj) }}
            </p>
          </div>
        </div>
      </div>
    </b-card-body>
    <!-- Spacer -->
    <hr class="invoice-spacing">
    <!-- Invoice Description: Table -->
    <!-- <b-table-lite
      responsive
      :items="editObj.products"
      :fields="fields"
    >
    </b-table-lite> -->
    <div class="mx-2" v-if='!modalLoading'>
      <ClientOrderRepeater 
        :editObj='editObj' 
        ref='clientRepeater' 
        :getRepeaterData='getRepeaterData' 
        :getPrices='getPrices'
      />
    </div>

    <hr class="invoice-spacing">
    <!-- Invoice Description: Total -->
    <b-card-body class="invoice-padding pb-0">
      <b-row>

        <!-- Col: Sales Persion -->
        <b-col
          cols="12" 
          md='7'
          class="mt-md-0 mt-3"
          order="2"
          order-md="1"
        >
          <!-- <b-card-text class="mb-0">
            <span class="font-weight-bold">Customer:</span>
            <span class="ml-75">{{editObj.address.name}}</span>
          </b-card-text> -->
          <b-form @submit.prevent='submit'>
            <b-row>
              <b-col cols='6'>
                <b-form-group
                  :label="$t('payment status')"
                  label-for='pay-select'
                >
                  <b-form-select
                    id=pay-select
                    :options='payOptions'
                    text-field="text"
                    value-field="value"
                    v-model="statusData.payment"
                    :disabled='$route.name == "Home"'
                  >
                  </b-form-select>
                </b-form-group>

                <b-form-group
                  :label="$t('delivery status')"
                  label-for='status-select'
                >
                  <b-form-select
                    id=status-select
                    :options='ordersStatus'
                    text-field="title"
                    value-field="id"
                    v-model="statusData.delivery_id"
                    :disabled='$route.name == "Home"'
                  >
                  </b-form-select>
                </b-form-group>
                
              </b-col>
              <b-col cols='6'>
                <b-form-group
                  :label="$t('driver')"
                  label-for='driver'
                >
                 <v-select
                  :options='filteredUsers'
                  label="name"
                  :reduce='driver => driver.id'
                  v-model='statusData.driver_id'
                  @search='searchDrivers'
                  :disabled='$route.name == "Home"'
                ></v-select>
                </b-form-group>

                <b-form-group
                  :label="$t('seller')"
                  label-for='seller'
                >
                   <v-select
                      :options='filteredSellers'
                      label="name"
                      :reduce='seller => seller.id'
                      v-model='statusData.seller_id'
                      @search='searchSellers'
                      :disabled='$route.name == "Home"'
                    ></v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-col>

        <!-- Col: Total -->
        <b-col
          cols="12"
          md='5'
          class="mt-md-6 d-flex justify-content-end"
          order="1"
          order-md="2"
        >
          <div class="invoice-total-wrapper">
            <span class="invoice-total-item">
              <p class="invoice-total-title">
               {{ $t('Subtotal')}}:
                <span class=" mx-1 invoice-total-amount">
                  <!-- {{editObj.total - (editObj.area ? editObj.area.delivery_charge : settings.ShippingRules.default)}} -->
                  {{subTotal}}
                </span>
              </p>
            </span>
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{$t('Delivery')}}:
                <span class=" mx-1 invoice-total-amount">
                  {{editObj.fees.shipping.value }}
                </span>
              </p>
            </div>
            <hr class="my-50">
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{$t('total')}}:
                <span class=" mx-1 invoice-total-amount">
                  {{parseFloat(subTotal) + parseFloat(editObj.fees.shipping.value)}}
                </span>
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>


<script>
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle,
  BFormSelect, BForm, BFormGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { title } from '@/@core/utils/filter'
import {mapActions, mapGetters} from 'vuex'
import vSelect from 'vue-select'
import ClientOrderRepeater from './ClientOrderRepeater';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BFormSelect, BForm, BFormGroup,
    vSelect,
    ClientOrderRepeater
  },
  data() {
    return {
       fields: [
        {
          key: 'title',
          label: this.$t('title')
        },
        {
          key: 'items[0].id',
          label: this.$t('code')
        },
        {
          key: 'items[0].price',
          label: this.$t('price')
        },
        {
          key: 'pivot.quantity',
          label: this.$t('quantity')
        },
        {
          key: 'pivot.total',
          label: this.$t('total')
        },

      ],
      payOptions: [
        {id: 1, text: this.$t('paid'), value: true},
        {id: 2, text: this.$t('not paid'), value: false},
      ],  
      detailedProducts: null,
      statusData: {
        payment: false,
        delivery_id: 0,
        driver_id: null,
        seller_id: null,
        cart: [],
      },
      subtotal: 0,
    }
  },
  props: ['editObj', 'closeModal'],
  mounted() {
    // console.log(this.editObj)
    this.$store.commit('setModalLoading', true);
    // console.log(this.detailedProducts)
    this.getSettings();
    this.getOrdersStatus({})
      .then(res => {
        this.$store.commit('setModalLoading', false);
        
      })
    this.statusData.payment = this.editObj.payments_status == true ? true : false; 
    this.statusData.delivery_id = this.editObj.status_id;
    if(this.editObj.driver) {
      this.statusData.driver_id = this.editObj.driver.id;
    }
    if(this.editObj.seller) {
      this.statusData.seller_id = this.editObj.seller.id;
    }
  },
  methods: {
    ...mapActions(['getSettings', 'getOrdersStatus', 'updateOrder', 'getOrders', 'getFilteredUsers']),
    formSubmit() {
      this.$refs.clientRepeater.submit()
        .then(res => {
          if (res == true) {
            // console.log(this.statusData)
            this.updateOrder({
              id: this.editObj.id,
              status_id: this.statusData.delivery_id,
              paid: this.statusData.payment,
              driver_id: this.statusData.driver_id,
              seller_id: this.statusData.seller_id,
              cart: this.statusData.cart,
            }).then(res => {
                console.log(res);
              this.closeModal();
              this.getOrders({});
            }).catch(err => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: err.response.data.results.message,
                  // icon: 'EditIcon',
                  variant: 'danger',
                },
              });
            })

          }
        })
    },
    getRepeaterData(data) {
      this.statusData = {
        ...this.statusData,
        cart: [...data],
      }
      console.log(this.statusData)
    },
    searchSellers(searchText) {
      this.getFilteredUsers({
        keywords: searchText,
        perm: 'users.seller'
      })
    },
    searchDrivers(searchText) {
      this.getFilteredUsers({
        keywords: searchText,
        perm: 'users.driver'
      })
    },
    getPrices(data) {
      this.subtotal = data;
    }
  },

  computed: {
    ...mapGetters(['settings', 'ordersStatus', 'filteredUsers', 'filteredSellers', 'modalLoading']),
    subTotal() {
      if(this.subtotal == 0) {
        return this.editObj.total - (this.editObj.area ? this.editObj.area.delivery_charge : this.settings.ShippingRules.default);
      }else {
        return this.subtotal;
      }
    }
  }
}
</script>




<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  
  

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
