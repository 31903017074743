import { render, staticRenderFns } from "./ProductRestForm.vue?vue&type=template&id=419010c7&"
import script from "./ProductRestForm.vue?vue&type=script&lang=js&"
export * from "./ProductRestForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports