
export default {
  
  data() {
    return {
      appAbbr: 'n360d',
      sWord: '4aRX5twzgN8kNYUbOSh4r4OpFdZF2APQ'
    }
  },
  created() {
  },
  methods: {
    isObject(item) {
      if(typeof item === 'object' || Array.isArray(item)) {
        return true;
      }else {
        return false;
      }
    },
    encryptValue(data) {
      return new Promise((resolve, reject) => {
        if(this.isObject(data)) {
          this.data = JSON.stringify(data);
        }else {
          this.data = data; 
        }
        // console.log(this.CryptoJS, 'brfor encryption');
        this.data = this.CryptoJS.AES.encrypt(this.data, this.sWord).toString();
        // console.log(this.data, 'after encryption');
        resolve(this.data);
      })
    },
    decryptValue(data) {
      return new Promise((resolve, reject) => {
        // console.log(data, 'before');
        this.data = this.CryptoJS.AES.decrypt(data, this.sWord).toString(this.CryptoJS.enc.Utf8);
        try {
          this.data = JSON.parse(this.data);
        } catch (err) {
          
        }
        resolve();
      })
    },
    storageSet(key, data) {
      return new Promise((resolve, reject) => {
        this.encryptValue(data)
          .then(res => {
            // console.log('done after', key, this.data)
            localStorage.setItem(key, res)
            resolve(true);
          }).catch(err => {
            // console.log('done after', key, this.data)
            console.error(err);
            reject(err);
          })
      })
    },
    storageGet(key) {
      if( localStorage.getItem(key) ) {
        this.data = localStorage.getItem(key);
        this.decryptValue(this.data)
        .then(res => {
          return this.data;
        }).catch(err => {
          console.error(err);
          return false
        });
        return this.data;
      }else {
        return false;
      }
    }
  }

}