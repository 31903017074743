<template>
  <validation-observer ref="simpleRules">
     <div>
       <!-- {{products[0].items[0].quantity}} -->
       <!-- {{products[0].items[0].quantity}} -->
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="submit"
        v-if='items.length > 0'
      >
        <!-- Row Loop -->
        <b-row
          v-for="(item, itemInd) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Item Name -->
          <b-col cols="3">
            <label for='ProductID'>
              {{$t('ProductID')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='ProductID'
            >
              <v-select
                v-model="item.product"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="fitleredProducts"
                @search=' searchProducts'
                @input="setProductItems(itemInd, item)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- {{item.titles}} -->
          <b-col>
            <label for='item_id'>
              {{$t('code')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='item_id'
            >
              <b-form-input
                id="item_id"
                placeholder="Item ID"
                v-model='item.item_id'
                :state="errors.length > 0 ? false:null"
                readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          
          <b-col >
            <label for='quantity'>
              {{$t('Quantity')}}
              <span v-if='item.product'>
                ( {{$t('Max: ')}} {{item.product.items[0].quantity}} )
              </span>
            </label>  
            <validation-provider
              #default="{ errors }"
              :rules="`required|between:1,${item.product ? item.product.items[0].quantity : 1}`"
              name='Quantity'
            >
              <b-form-input
                id="quantity"
                placeholder="Quantity"
                v-model='item.quantity'
                :state="errors.length > 0 ? false:null"
                type='number'
                @input='calculatePrices'
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- Cost -->
          <b-col>
            <label for='price'>
              {{$t('Unit Price')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='Value'
            >
            <!-- {{item.product.items[0].price}} -->
              <b-form-input
                id="price"
                placeholder="price"
                v-model='item.product.items[0].price'
                :state="errors.length > 0 ? false:null"
                type='number'
                disabled
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- Quantity -->
          <b-col >
            <label for='totalPaid'>
              {{$t('total')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='total'
            >
              <b-form-input
                id="total"
                :value='item.product.items[0].price ? item.product.items[0].price * item.quantity : 0'
                :state="errors.length > 0 ? false:null"
                readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="1"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(itemInd)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <!-- <span>Delete</span> -->
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
      class="btn-icon my-1"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>{{$t('add new')}}</span>
    </b-button>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
    BFormCheckbox, BFormTextarea, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import {mapActions, mapGetters} from 'vuex'
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox, BFormTextarea, BInputGroup, BInputGroupAppend,
    ValidationProvider, ValidationObserver,
    ToastificationContent,
    vSelect
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
      ],
      defaultItem: {
        product_id: null,
        item_id: null,
        price: null,
        quantity: null,
      },
      formLoading: false,
      tempitems: [],
      subtotal: 0,
      required,
      between,
    }
  },
  props: ['getRepeaterData', 'editObj', 'getPrices'],
  watch: {
    items: function() {
      if (this.items.length == 0) {
        this.repeateAgain();
      }
      this.calculatePrices();
    },
    // editObj: function() {
    //   this.items = [...this.editObj.items];
    //   this.$forceUpdate();
    // }
  },  
  mounted() {
    
    this.formLoading = true;
      // console.log(this.editObj)
    if(this.editObj != null) {
      if(this.editObj.items && this.editObj.items != null) {
        this.items = [...this.editObj.items];
      }else if (this.editObj.cart) {
        this.items = this.editObj.cart.map((el, index) => {
          el.product = this.editObj.products[index]
          return el;
        })
      }
      this.$forceUpdate();
    }else {
      this.formLoading = false;
      this.repeateAgain()
    }
    this.formLoading = false;
  },
  methods: {
    ...mapActions(['getProducts']),
    repeateAgain() {
      // let newId = this.createItemId(); 
      this.items.push({
        ...this.defaultItem,
        // id: newId,
      })
    },
    submit() {
      let total = 0;
      this.items.forEach(el => {
        let price = parseFloat(el.product.items[0].price) * parseInt(el.quantity)
        total = total + price;
      })
      if(total > 150) {
        return this.$refs.simpleRules.validate()
          .then(res => {
            this.valid = res;
            if (res == true) {
              this.getRepeaterData(this.items)
              return true;
            }else {
              return false;
            }
        })
      }else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Total Amount Must Be More Than 150',
            icon: 'EditIcon',
            variant: 'danger',
          },
        });
      }
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    changeNameLang(item) {
      if(item.nameLang == 'ar') {
        item.nameLang = 'en'
      }else {
        item.nameLang = 'ar'
      }
    },
    checkEmptyLocale(item) {
      console.log(item)
      if(!item.ar || !item.en) {
        if(!item.ar) {
          item.ar = item.en;  
        }else {
          item.en = item.ar;
        }
      }
      item.titles.ar = item.ar;
      item.titles.en = item.en;
    },
    changeQuantityType(item) {
      console.log(item)
      if(item.quantity == -1) {
        item.quantity = 1;
      }else {
        item.quantity = -1;
      }
    },
    searchProducts(value) {
      this.getProducts({
        keywords: value,
      })
    },
    setProductItems(index, item) {
      item.item_id = item.product.items[0].id;
      item.product_id = item.product.id;
      this.$forceUpdate();
      // console.log(index, item);
      // console.log(this.items);
    },
    calculatePrices() {
      let pricesArray = this.items.map((item, index) => {
        if(item.product) {
          return item.product.items[0].price * item.quantity;
        }else {
          return item = 0;
        }
      });
      if(this.getPrices) {
        this.subtotal = pricesArray.reduce((acc, item) => {
          return acc + item;
        }, 0);
        this.getPrices(this.subtotal);
      }
    }
  },
  computed: {
    ...mapGetters(['products', 'settings']),
    fitleredProducts() {
      return this.products.filter(el => el.status == true && el.items[0].quantity)
    }
  }
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.repeater-form {
  padding-bottom: 150px
}
</style>