import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueCryptojs from 'vue-cryptojs'
import i18n from '@/libs/i18n'

import router from './router'
import store from './store'
import App from './App.vue'

import storage from './libs/storage';
import helper from './libs/storage/helper';
import permissions from './libs/storage/permissions'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCryptojs)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')
require('@core/scss/vue/libs/vue-sweetalert.scss');
require('@core/scss/vue/libs/vue-select.scss');

// import assets styles
require('@/assets/scss/style.scss')

// import mixins
Vue.mixin(storage);
Vue.mixin(helper);
Vue.mixin(permissions);

// import components


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
