import axios from 'axios';
import store from '../store/auth/auth';
import router from '../router/index';
// import storage from './storage';

const strataIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'http://test.optivius.com/embassy/public_html/v1',
  // baseURL: 'https://api.sooqjinan.com/v1',
  // headers: {'X-Custom-Header': 'foobar'}
});

strataIns.interceptors.request.use(function (config) {
  // console.log(store)
  store.mutations.setLoading(store.state, true);
  let user = store.state.user;
  let token = user ? user.token : null;
  config.headers.Authorization = `Bearer ${token}`;
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

strataIns.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  store.mutations.setLoading(store.state, false);
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  store.mutations.setLoading(store.state, false);
  if (error.response.status == 401 && error.response.data.results.message == "Unauthorized.") {
    localStorage.removeItem('u_'+this.appAbbr);
    localStorage.removeItem('up_'+this.appAbbr);
    setTimeout(() => {
      router.go();
    }, 500);
  }
  return Promise.reject(error);
});
// Vue.prototype.$http = axiosIns

export default strataIns;
