var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('div',[(_vm.items.length > 0)?_c('b-form',{ref:"form",staticClass:"repeater-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},_vm._l((_vm.items),function(item,itemInd){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"cols":"3"}},[_c('label',{attrs:{"for":"ProductID"}},[_vm._v(" "+_vm._s(_vm.$t('ProductID'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"ProductID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.fitleredProducts},on:{"search":_vm.searchProducts,"input":function($event){return _vm.setProductItems(itemInd, item)}},model:{value:(item.product),callback:function ($$v) {_vm.$set(item, "product", $$v)},expression:"item.product"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('label',{attrs:{"for":"item_id"}},[_vm._v(" "+_vm._s(_vm.$t('code'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"item_id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"item_id","placeholder":"Item ID","state":errors.length > 0 ? false:null,"readonly":""},model:{value:(item.item_id),callback:function ($$v) {_vm.$set(item, "item_id", $$v)},expression:"item.item_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('label',{attrs:{"for":"quantity"}},[_vm._v(" "+_vm._s(_vm.$t('Quantity'))+" "),(item.product)?_c('span',[_vm._v(" ( "+_vm._s(_vm.$t('Max: '))+" "+_vm._s(item.product.items[0].quantity)+" ) ")]):_vm._e()]),_c('validation-provider',{attrs:{"rules":("required|between:1," + (item.product ? item.product.items[0].quantity : 1)),"name":"Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"quantity","placeholder":"Quantity","state":errors.length > 0 ? false:null,"type":"number"},on:{"input":_vm.calculatePrices},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('label',{attrs:{"for":"price"}},[_vm._v(" "+_vm._s(_vm.$t('Unit Price'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"Value"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","placeholder":"price","state":errors.length > 0 ? false:null,"type":"number","disabled":""},model:{value:(item.product.items[0].price),callback:function ($$v) {_vm.$set(item.product.items[0], "price", $$v)},expression:"item.product.items[0].price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',[_c('label',{attrs:{"for":"totalPaid"}},[_vm._v(" "+_vm._s(_vm.$t('total'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"total"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"total","value":item.product.items[0].price ? item.product.items[0].price * item.quantity : 0,"state":errors.length > 0 ? false:null,"readonly":""}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(itemInd)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}})],1)],1)],1)}),1):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon my-1",attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t('add new')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }