<template>
  <div>
    <b-form @submit.prevent="submit">
      <label for="file-input">
        
        <span v-if="title">
          {{ title + ' image'}}
        </span>
        <span v-else-if="$route.name == 'Sections'">
          {{ $t("Media") }}
        </span>
        <span v-else>
          {{ $t("Images") }}
        </span>
      </label>
      <b-form-file
        ref="file-input"
        :id="'file-input'+title"
        v-model="file"
        class="mb-2"
        :multiple="multiple ? multiple : false"
      />
      <!-- <b-button
        variant="primary"
        @click="file = null"
        size="sm"
      >
        {{$t('Reset')}}
      </b-button> -->

      <b-card class="p-1" v-if="editObj != null">
        <img
          v-if="$route.name == 'Projects'"
          :src="editObj.images[0] && editObj.images[0].url"
          width="10%"
          height="100%"
        />
        <img
          v-if="$route.name == 'Categories'"
          :src="editObj.icon_url"
          width="10%"
          height="100%"
        />
        <img
          v-if="$route.name == 'Sections'"
          :src="editObj.media_url"
          width="10%"
          height="100%"
        />
        <img
          v-if="title == 'Cover' && editObj.cover"
          :src="editObj.cover"
          width="10%"
          height="100%"
        />
        <img
          v-if="title == 'Player' && editObj.player"
          :src="editObj.player"
          width="10%"
          height="100%"
        />
        <img
          v-if="title == 'Player' && editObj.photo"
          :src="editObj.photo"
          width="10%"
          height="100%"
        />
        <img
          v-if="urlRoutes"
          :src="editObj.image_url"
          width="10%"
          height="100%"
        />
        <!-- <div class="my-1" v-if='editObj.icon_url || (editObj.images[0] && editObj.images[0].url)'> -->
        <!-- <b-button
            variant="danger"
            class="btn"
            @click="deleteImg"
          >
            <feather-icon icon="Trash2Icon" />
            delete
          </b-button> -->
        <!-- </div> -->
      </b-card>
    </b-form>
  </div>
</template>

<script>
import { BFormFile, BButton, BForm, BCard, BCardFooter } from "bootstrap-vue";
import { mapActions } from "vuex";
export default {
  components: {
    BFormFile,
    BButton,
    BForm,
    BCard,
    BCardFooter,
  },
  props: ["getImgData", "editObj", "getMediaData", "multiple", 'title'],
  data: () => ({
    file: null,
    base64: {
      ext: "",
      file: "",
    },
  }),
  methods: {
    ...mapActions(["createProduct"]),
    submit() {
      if (this.file) {
        if (this.$route.name == "Sections") {
          this.getMediaData(this.file);
        } else {
          this.getBase64(this.file).then((res) => {
            this.getImgData(res, this.title);
            return true;
          });
        }
      }
      // this.getImgForm()
    },
  },
  computed: {
    urlRoutes() {
      let routes = ["Labels", "Testmonials", "Banners", "News"];
      return routes.includes(this.$route.name);
    },
  },
};
</script>
 