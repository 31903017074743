<template>
<validation-observer ref="simpleRules">
  <b-form @submit.prevent='formSubmit'>

    <b-row>
      <b-col cols="4">
        <label for='start'>
          {{$t('Start Row')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          rules="required"
          name='start'
        >
        <b-form-input
          id="start"
          v-model='options.start'
          type='number'
        />
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

       <b-col cols="4">
        <label for='code'>
          {{$t('Code Column')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          rules="required"
          name='code'
        >
        <b-form-input
          id="code"
          v-model='options.barcode'
        />
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
       
      <b-col cols="4">
        <label for='category'>
          {{$t('Category Column')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          rules="required"
          name='category'
        >
        <b-form-input
          id="category"
          v-model='options.category'
        />
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
    </b-row>
    
    <b-row>
      <b-col cols="4">
        <label for='arname'>
          {{$t('Arabic Name Column')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          rules="required"
          name='arname'
        >
        <b-form-input
          id="start"
          v-model='options.name.ar'
        />
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

       <b-col cols="4">
        <label for='code'>
          {{$t('English Name Column')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          rules="required"
          name='code'
        >
        <b-form-input
          id="code"
          v-model='options.name.en'
        />
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
       
      <b-col cols="4">
        <label for='price'>
          {{$t('Price Column')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          rules="required"
          name='price'
        >
        <b-form-input
          id="price"
          v-model='options.price'
        />
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="4">
        <label for='image'>
          {{$t('Image Path Column')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          name='image'
        >
        <b-form-input
          id="start"
          v-model='options.image_path'
        />
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>

       <b-col cols="4">
        <label for='quantity'>
          {{$t('Quantity  Column')}}
        </label>  
        <validation-provider
          #default="{ errors }"
          name='quantity'
        >
        <b-form-input
          id="code"
          v-model='options.quantity'
        />
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
    </b-row>

    <ImportFileForm :getFileData='getFileData' ref='fileForm'/>

  </b-form>
</validation-observer >
</template>

<script>
import {
  BForm, BFormGroup, BFormCheckbox, BFormInput, BRow, BCol, BFormSelect,
  
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

import ImportFileForm from './ImportFileForm';
import ToastificationContentVue from '../toastification/ToastificationContent.vue'

export default {
  components: {
    vSelect,
    BForm,
    BFormGroup, 
    BFormCheckbox, BFormInput,BFormSelect,
    BRow, BCol,
    ValidationProvider, ValidationObserver,
    ImportFileForm

  },
  data() {
    return {
      options: {
        barcode: "A",
        category: "B",
        name: {
            ar: "C",
            en: "D"
        },
        image_path: "E",
        price: "F",
        quantity: "",
        start: 2
      },
      file: {},
      initialCategories: [],
      required
    }
  },
  props: ['closeModal'],
  mounted() {
   
  },
  methods: {
    ...mapActions(['importProducts', 'getCategory']),
    formSubmit() {
      this.$store.commit('setModalLoading', true);
      this.$refs.fileForm.submit()
        .then(res => {
          console.log(res)
          if (res == true) {
            setTimeout(() => {
              this.importProducts({
                options: this.options,
                file: this.file,
              }).then(res => {
                
                this.$toast({
                  component: ToastificationContentVue,
                  props: {
                    title: `New products :  ${res.data.results.new_products}`,
                    icon: 'FileIcon',
                    variant: 'success',
                  }
                })
                this.$store.commit('setModalLoading', false);
                this.closeModal()
              })
            }, 100)
          }
        })
    },
    getFileData(data) {
      // console.log(data)
      this.file = data
    }
  },
  computed: {
    ...mapGetters(['categories',]),
  
  }
}
</script>