<template>
  <b-form @submit.prevent='submit'>

    <b-row>
      <b-col cols='6'>
        <b-form-group
          :label='$t("Categories")'
          label-for='categories'
        >
          <!-- <b-form-select
            id="categories"
            v-model='restData.categories'
            :options='filteredCategories'
            value-field="id"
            text-field="title"
          >
          </b-form-select> -->
          <v-select
            id="categories"
            v-model='restData.categorys_id'
            :options='filteredCategories'
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            multiple
            label='title'
            :reduce='cat => cat.id'
            @search='searchCategories'
          >
          </v-select>
        </b-form-group>
       
      </b-col>
      <b-col cols='6'>
        <b-form-group
          :label="$t('status')"
          label-for='status'
        >
          <b-form-checkbox
            v-model='restData.status'
            id="status"
            switch
            inline
          >
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>


  </b-form>
</template>

<script>
import {
  BForm, BFormGroup, BFormCheckbox, BFormInput, BRow, BCol, BFormSelect
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'


export default {
  components: {
    vSelect,
    BForm,
    BFormGroup, 
    BFormCheckbox, BFormInput,BFormSelect,
     BRow, BCol
  },
  data() {
    return {
      restData: {
        categorys_id: [],
        status: true,
      },
      initialCategories: [],
      options: []
    }
  },
  props: ['getRestData', 'editObj'],
  mounted() {
    if(this.editObj != null) {
      // console.log(this.editObj.categorys_id)
      this.$store.commit('setModalLoading', true);
      this.restData.categorys_id = this.editObj.categorys_id;
      this.restData.status = this.editObj.status;
      this.restData.categorys_id.forEach(el => {
        if(el && !this.categories.find(cat => cat.id == el)) {
          this.getCategory(el)
            .then(res => {
              let data = res.data.results;
              data.title = data.titles.ar;
              this.initialCategories.push(data);
            })
        }
      })  
      this.$store.commit('setModalLoading', false);
    }
  },
  methods: {
    ...mapActions(['getCategories', 'getCategory']),
    submit() {
      this.getRestData({...this.restData})
      return true;
    },
    searchCategories(searchText) {
      this.getCategories({
        keywords: searchText
      })
        .then(res => {
          console.log(res)
        })
    }
  },
  computed: {
    ...mapGetters(['categories',]),
    filteredCategories() {
      let array = this.initialCategories.concat(this.categories.filter(el => el.parent_id == null && this.editObj != null ? el.id != this.editObj.id : true));
      return array;  
    }
  }
}
</script>