<template>
  <validation-observer ref="supprules" class="">
    <b-form @submit.prevent='formSubmit' v-if='!loading && !formLoading'>
      <b-row>
        <b-col cols='6'>
          <b-form-group
            :label="$t('name')"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='title'
            >
              <b-form-input
                id='name'
                v-model="formObj.name"
              ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols='6'>
          <b-form-group
            :label="$t('phone')"
            label-for="phone"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='phone'
            >
              <b-form-input
                id='phone'
                v-model="formObj.phone"
                type='number'
              ></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols='6'>
          <b-form-group
            :label="$t('Notes')"
            label-for="notes"
          >
            <b-form-input
              id='notes'
              v-model="formObj.notes"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols='6'>
          <b-form-group
            :label="$t('Status')"
            label-for="status"
          >
            <b-form-checkbox
              id='status'
              switch
              v-model="formObj.status"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
     

     
    
     

      


    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormTextarea, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations';
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver
  },

  data() {
    return {
      formLoading: false,
      formObj: {
        status: true,
        name: '',
        phone: '',
        notes: '',
      },
      required,
    }
  },
  props: ['editObj', 'closeModal'],
  mounted() {
    console.log(this.editObj)
    if(this.editObj != null) {
      this.formLoading = true;
      this.formObj = this.editObj;
      this.formLoading = false;
    }
  },
  methods: {
    ...mapActions(['createSupplier', 'getSuppliers']),
     formSubmit() {
       console.log(this.formObj)
       this.$refs.supprules.validate()
        .then(res => {
          if (res == true) {
            // return true;
            this.createSupplier(this.formObj)
              .then(res => {
                this.getSuppliers({})
                this.closeModal()
              })
          }else {
            // return false;
          }
      })

     }
  },
  computed: {
    ...mapGetters([]),

  }
}
</script>