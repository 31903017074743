<template>
  <div class="" v-if='!formLoading'>
    <BaseForm 
      v-if='formObj != null && !formLoading' 
      :editObj='formObj' 
      :getBaseData='getBaseData' 
      ref='baseForm'
    />
    <hr/>
    <BaseForm 
      v-if='formObj != null && !formLoading' 
      :editObj='formObj' 
      :getBaseData='getTagsData' 
      ref='tagsForm'
      :noDescription="true"
      :title='"Tags"'
      
    />
    <hr/>
    <ResponsiveFieldsForm 
      v-if='formObj != null && !formLoading' 
      :defaultItem="defaultItem" 
      :editObj='formObj' 
      :getRestData='getRestData' 
      :formFields="restFields" 
      ref='restForm' 
      
    />
    <!-- <hr/> -->
    <ImgForm 
      :editObj='formObj' 
      :getMediaData='getMediaData'
      :getImgData='getImgData' 
      ref='imgForm'
    />
    
  </div>
</template>

<script>
// import ResponsiveForm from './ResponsiveForm.vue'
import ResponsiveFieldsForm from './ResponsiveFieldsForm.vue';
import BaseForm from './BaseForm.vue';
import ProductRestForm from './ProductRestForm.vue';
import ImgForm from './ImgForm.vue';

import {
  BRow, BCol
} from 'bootstrap-vue';

import {mapActions,mapGetters} from 'vuex';

export default {
  components: {
    ResponsiveFieldsForm,
    BaseForm,
    ProductRestForm,
    ImgForm,
    BRow, BCol
  },
  data() {
    return {
      formObj: null,
      formLoading: false,
      formData: {},
      defaultItem: {
        // color: '#FFFFFF',
        status: true,
      }
    }
  },

  props: ['editObj', 'closeModal',],
  mounted() {
    console.log(this.editObj)
    this.getCategories({})
    if(this.editObj != null) {
      this.loadShowedNews(this.editObj.id)
        .then(res => {
          this.formObj = res.data.results;
          this.formObj.title = this.formObj.titles.en;
          this.formData.id = this.editObj.id;
          setTimeout(() => {
            this.formLoading = false;
          }, 100)
        })
    }else {
      this.formObj = {
        status: true
      }
    }
  },
  methods: {
    ...mapActions(['loadNewsList', 'loadShowedNews', 'deleteNews', 'updateNews', 'getCategories',]),
    formSubmit()  {
      this.$refs.restForm.submit()
        .then(res => {
          if(res == true) {
            if(res == true) {
              this.$store.commit('setModalLoading', true);
              this.$refs.restForm.submit();
              this.$refs.imgForm.submit();
              this.$refs.tagsForm.submit();
              this.$refs.baseForm.submit();
              this.checkNulldesc()
              console.log(this.formData)
                setTimeout(() => {
                  console.log(this.formData)
                  this.updateNews(this.formData)
                    .then(res => {
                      this.closeModal();
                      this.loadNewsList({});
                      this.$store.commit('setModalLoading', false);
                    })
                }, 100)
            }
          }
        })
    },
    
    getBaseData(data) {
      this.formData.titles = data.titles;
      this.formData.descriptions = data.descriptions;
    },
    getImgData(data) {
      console.log(data)
      this.formData.base64 = [];
      // if(this.editObj && this.editObj.image) {
      //   this.formData.base64 = [
      //     {
      //       ...this.editObj.image,
      //       delete: true
      //     }
      //   ]
      // }
        
      this.formData.base64 = [
        ...this.formData.base64,
        data,
      ];
    },
    getTagsData(data) {
      this.formData.tags = data.titles
    } ,
    getRestData(data) {
      this.formData = {
        ...this.formData,
        ...data,
      }
    },
    getMediaData(data) {
      if(this.isFileImage(data)) {
        this.formData.media_type = 0;
      }else {
        this.formData.media_type = 1;
      }
      this.formData.base64 = data;
    },
    getRepeaterData(data) {
      this.formData.items = data;
    },
    checkNulldesc() {
      if(this.formData.descriptions && (!this.formData.descriptions.ar || this.formData.descriptions.ar == null)) {
        this.formData.descriptions.ar = this.formData.descriptions.en
      }
      if(this.formData.descriptions && (!this.formData.descriptions.en || this.formData.descriptions.en == null)) {
        this.formData.descriptions.en = this.formData.descriptions.ar
      }
    },
    searchCategories(searchText) {
      this.getCategories({
        keywords: searchText,
        status: 1,
      })
    }
  },

  computed: {
    ...mapGetters(['newsList', 'news', 'showNews', 'modalLoading', 'pages', 'categories']),
    restFields() {
      return [
        {
          name: 'Category', type: 'searchSelect', rules: 'required', 
          model: 'category_id', options: this.categories, label: 'title', 
          searchItems: this.searchCategories,
          cols: 12,
        },
        {name: 'status', type: 'checkbox', rules: 'required', model: 'status' },
      ]
      
    }
  }

}
</script>