var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('layout-vertical',{scopedSlots:_vm._u([{key:"navbar",fn:function(ref){
var toggleVerticalMenuActive = ref.toggleVerticalMenuActive;
return [_c('navbar',{attrs:{"toggle-vertical-menu-active":toggleVerticalMenuActive}})]}}])},[_c('b-overlay',{attrs:{"rounded":"sm","show":_vm.loading,"variant":"transparent","blur":"2px"}},[_c('router-view',{attrs:{"id":"mainComp","here":true,"openModal":_vm.openModal,"closeModal":_vm.closeModal,"confirmDelete":_vm.confirmDelete}})],1)],1),_c('b-modal',{ref:"layoutModal",attrs:{"no-close-on-backdrop":"","cancel-variant":"outline-secondary","cancel-title":_vm.$t('Cancel'),"ok-title":_vm.$route.name == 'Orders' && _vm.type == 'Show'
        ? _vm.$t('Downlaod')
        : _vm.$t('Submit'),"centered":"","size":_vm.routesLargerModal && _vm.type != 'Import' ? 'xl' : 'lg',"title":_vm.title},on:{"ok":_vm.submitForm,"hidden":_vm.clearData}},[_c('b-overlay',{attrs:{"rounded":"sm","show":_vm.modalLoading,"variant":"transparent","blur":"2px"}},[(
          _vm.$route.name == 'Shows' 
        )?_c('ShowsForm',{ref:"showsForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(_vm.$route.name == 'Labels' && (_vm.type == 'Create' || _vm.type == 'Edit'))?_c('LabelsForm',{ref:"showOrderForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(
          _vm.$route.name == 'Presenters' && (_vm.type == 'Create' || _vm.type == 'Edit')
        )?_c('PresentersForm',{ref:"presentersForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(_vm.$route.name == 'Pages')?_c('PagesForm',{ref:"orderStatusForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(_vm.$route.name == 'Sections')?_c('SectionsForm',{ref:"productsForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(_vm.$route.name == 'News')?_c('NewsForm',{ref:"newsForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(_vm.$route.name == 'Sliders')?_c('SlidersForm',{ref:"slidersForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(_vm.$route.name == 'Banners')?_c('BannersForm',{ref:"bannersForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(_vm.$route.name == 'Jobs')?_c('JobsForm',{ref:"jobsForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(_vm.$route.name == 'Projects')?_c('ProjectsForm',{ref:"projectsForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(_vm.$route.name == 'Categories' || _vm.$route.name == 'JobCategories')?_c('CategoryForm',{ref:"categoryForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),((_vm.$route.name == "Roles"))?_c('RolesForm',{ref:"rolesForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(_vm.$route.name == "Accounts")?_c('AccountsForm',{ref:"profileForm",attrs:{"closeModal":_vm.closeModal,"editObj":_vm.editObj}}):_vm._e(),(_vm.$route.name == 'ContactUs')?_c('b-list-group',{staticClass:"mt-1"},_vm._l((_vm.editObj),function(key,val){return _c('b-list-group-item',{key:key + val},[_vm._v(_vm._s(val)+": "+_vm._s(key))])}),1):_vm._e(),(_vm.$route.name == 'Subscribtions')?_c('b-list-group',{staticClass:"mt-1"},_vm._l((_vm.editObj),function(key,val){return _c('b-list-group-item',{key:key + val},[_vm._v(_vm._s(val)+": "+_vm._s(key))])}),1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }