<template>
  <validation-observer ref="simpleRules">
     <div>
      <b-form
        ref="form"
        class="repeater-form"
        @submit.prevent="submit"
        v-if='items.length > 0'
      >
        <!-- Row Loop -->
        <b-row
          v-for="(item, itemInd) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Item Name -->
          <b-col>
            <label for='ProductID'>
              {{$t('ProductID')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='ProductID'
            >
              <v-select
                v-model="item.product"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="products"
                @search=' searchProducts'
                @input="setProductItems(itemInd, item)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- {{item.titles}} -->
          <b-col>
            <label for='item_id'>
              {{$t('code')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='item_id'
            >
              <b-form-input
                id="item_id"
                placeholder="Item ID"
                v-model='item.item_id'
                :state="errors.length > 0 ? false:null"
                readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          
          <b-col cols='1'>
            <label for='quantity'>
              {{$t('Quantity')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='Quantity'
            >
              <b-form-input
                id="quantity"
                placeholder="Quantity"
                v-model='item.quantity'
                :state="errors.length > 0 ? false:null"
                type='number'
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- Cost -->
          <b-col cols='1'>
            <label for='value'>
              {{$t('Unit Price')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='Value'
            >
              <b-form-input
                id="value"
                placeholder="Value"
                v-model='item.value'
                :state="errors.length > 0 ? false:null"
                type='number'
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- Quantity -->
          <b-col >
            <label for='net_value'>
              {{$t('totalAfterDiscount')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              name='net_value'
            >              
              <b-form-input
                id="net_value"
                v-model='item.net_value'
                :state="errors.length > 0 ? false:null"
                type='number'
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col >
            <label for='totalPaid'>
              {{$t('totalPaid')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='totalPaid'
            >
              <b-form-input
                id="totalPaid"
                :value='item.net_value ? item.net_value * item.quantity : item.value * item.quantity'
                :state="errors.length > 0 ? false:null"
                readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col >
            <label for='expireDate'>
              {{$t('expireDate')}}
            </label>  
            <validation-provider
              #default="{ errors }"
              rules="required"
              name='expireDate'
            >
              <b-form-datepicker
                id="expireDate"
                v-model='item.expire_date'
                @input='getFormatedDate(item)'
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="1"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(itemInd)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <!-- <span>Delete</span> -->
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
      class="btn-icon my-1"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>{{$t('add new')}}</span>
    </b-button>
  </validation-observer>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
    BFormCheckbox, BFormTextarea, BInputGroup, BInputGroupAppend,
    BFormDatepicker
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import {mapActions, mapGetters} from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormCheckbox, BFormTextarea, BInputGroup, BInputGroupAppend,
    ValidationProvider, ValidationObserver,
    vSelect, BFormDatepicker
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
      ],
      defaultItem: {
        product_id: null,
        item_id: null,
        value: null,
        net_value: null,
        quantity: null,
        expire_date: null,
      },
      formLoading: false,
      tempitems: [],
      
      required,
    }
  },
  props: ['getRepeaterData', 'editObj'],
  watch: {
    items: function() {
      if (this.items.length == 0) {
        this.repeateAgain();
      }
    },
  },  
  mounted() {
    this.formLoading = true;
    if(this.editObj != null) {
      this.items = [...this.editObj.items];
      this.$forceUpdate();
    }else {
      this.formLoading = false;
      this.repeateAgain()
    }
    // this.formLoading = false;
  },
  methods: {
    ...mapActions(['getProducts']),
    repeateAgain() {
      // let newId = this.createItemId(); 
      this.items.push({
        ...this.defaultItem,
        // id: newId,
      })
    },
    submit() {
      return this.$refs.simpleRules.validate()
        .then(res => {
          this.valid = res;
          if (res == true) {
            this.getRepeaterData(this.items)
            return true;
          }else {
            return false;
          }
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    changeNameLang(item) {
      if(item.nameLang == 'ar') {
        item.nameLang = 'en'
      }else {
        item.nameLang = 'ar'
      }
    },
    checkEmptyLocale(item) {
      console.log(item)
      if(!item.ar || !item.en) {
        if(!item.ar) {
          item.ar = item.en;  
        }else {
          item.en = item.ar;
        }
      }
      item.titles.ar = item.ar;
      item.titles.en = item.en;
    },
    changeQuantityType(item) {
      console.log(item)
      if(item.quantity == -1) {
        item.quantity = 1;
      }else {
        item.quantity = -1;
      }
    },
    searchProducts(value) {
      this.getProducts({
        keywords: value,
      })
    },
    setProductItems(index, item) {
      item.item_id = item.product.items[0].id;
      item.product_id = item.product.id;
      this.$forceUpdate();
      // console.log(index, item);
      // console.log(this.items);
    },
    getFormatedDate(item) {
      console.log(item.expire_date);
      // this.formateDateShort2(item.expire_date);
    } 
  },
  computed: {
    ...mapGetters(['products']),
  }
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.repeater-form {
  padding-bottom: 150px
}
</style>